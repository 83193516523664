import "./wantToJoinBranch.css";
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin";
import AnimatedPage from "../animationPage";
import videoPlaceholder from "../../img/videoPlaceholder.png";
import moneyBag from "../../img/wantToJoinLC5.png";
import React from "react";
export default function WantToJoinOffSteking() {
  return (
    <AnimatedPage>
      <div className="want_to_join_containers">
        <ArrowButtonWantToJoin />
        <p className="want_to_join_club_balance_title">
          club balance: как он работает
        </p>
        <iframe
          width="99%"
          height="173"
          src="https://www.youtube.com/embed/84XhnlEQhCk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="want_to_join_parts_undertitle">
          <b>В этом видео вы узнаете,</b> как отключить автостейкинг монеты Ulx
          в кабинете Mavie.
        </p>
        <img className="want_to_join_moneyBag" src={moneyBag} />
        <p className="want_to_join_common_text">
          <b>Обратите внимание:</b> когда вы отключаете автостейкинг, тогда вы
          теряете бонусы которые вы получаете в первые 12 месяцев.
        </p>
      </div>
    </AnimatedPage>
  );
}
