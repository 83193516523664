import "./loginPage.css";
import ultronLogo from "../../img/Ultron3d.svg";

import React from "react";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../const";

export default function LoginPage() {
  const Navigate = useNavigate();
  const [tel, setTel] = useState("+");

  const inputRef = useRef();
  const [errorText, seterrorText] = useState("");
  const [buttonColor, setButtonColor] = useState(false);
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const buttonIn = useRef(null);
  // const forgotPass = useRef(null)
  const buttonReg = useRef(null);
  const helpRef = useRef(null);
  const autoAuth = () => {
    let data = new FormData();
    data.append("tel", localStorage.loginerTel);
    data.append("tgId", localStorage.getItem("tgId"));
    fetch(apiUrl + "/authMain.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data.split(",");
        localStorage.setItem("name", arr[0]);
        localStorage.setItem("imgPath", arr[1]);
        localStorage.setItem("referal", arr[3]);
        localStorage.setItem("link", arr[4]);

        console.log(arr);
        if (arr[5] == 1) {
          Navigate("/welcome");
        } else if (arr[5] == 0) {
          Navigate("/registrationWaiter");
        }
      });
  };
  useEffect(() => {
    let tg = window.Telegram.WebApp;
    let formData = new FormData();
    //formData.append("id", tg.initDataUnsafe.user.id);
    formData.append("id", "1406621219");

    fetch(apiUrl + "/authMain.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != 0) {
          let arr = data.split(",");
          localStorage.setItem("name", arr[0]);
          localStorage.setItem("imgPath", arr[1]);
          localStorage.setItem("referal", arr[3]);
          localStorage.setItem("link", arr[4]);
          localStorage.setItem("tel", arr[2]);
          localStorage.setItem("ID", arr[6]);
          localStorage.setItem("tgId", arr[7]);
          if (arr[5] == 1) {
            // localStorage.setItem("tgId", tg.initDataUnsafe.user.id);
            // localStorage.setItem(
            //   "tgObj",
            //   JSON.stringify(tg.initDataUnsafe.user)
            // );

            localStorage.setItem("isActivated", arr[5]);
            Navigate("/welcome");
          } else if (arr[5] == 0) {
            Navigate("/registrationWaiter");
          }
        } else {
          localStorage.setItem("tgId", tg.initDataUnsafe.user.id);
          // localStorage.setItem("tgId", "675264984");
          localStorage.setItem("tgObj", JSON.stringify(tg.initDataUnsafe.user));
          Navigate("/registrationInfo");
        }
      });

    //setTg("675264984");
  }, []);

  useEffect(() => {
    // if (localStorage.telReg) {
    //   Navigate("/registrationWaiter");
    // }
    // if (localStorage.tel && localStorage.password) {
    //   authAuto();
    // }
    setTimeout(() => {
      if (LogoRef.current) {
        LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      }

      // firstInput.current.style.transform = "scale(1)";
      // secInput.current.style.transform = "scale(1)";
      // buttonIn.current.style.transform = "scale(1)";
      // // forgotPass.current.style.transform="scale(1)"
      // buttonReg.current.style.transform = "scale(1)";
      if (helpRef.current) {
        helpRef.current.style.transform = "scale(1)";
      }
    }, 500);
  }, []);

  // const authAuto = () => {
  //   const data = new FormData();

  //   data.append("tel", localStorage.tel);
  //   data.append("password", localStorage.password);
  //   fetch(apiUrl + "/auth.php", {
  //     method: "post",
  //     body: data,
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       let arr = data.split(",");
  //       if (arr[3] == "1") {
  //         Navigate("/welcome");
  //       }
  //     });
  // };
  // const handleTelegramResponse = (response) => {
  //   console.log(response);
  // };
  // const auth = (flag) => {
  //   const data = new FormData();
  //   console.log(tel);

  //   data.append("tel", tel);
  //   data.append("password", form.password);

  //   fetch(apiUrl + "/auth.php", {
  //     method: "post",
  //     body: data,
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //       if (data != "error") {
  //         let arr = data.split(",");
  //         localStorage.setItem("name", arr[0]);
  //         localStorage.setItem("link", arr[1]);
  //         localStorage.setItem("imgPath", arr[2]);
  //         localStorage.setItem("tel", tel);
  //         localStorage.setItem("password", form.password);
  //         const datar = new FormData();
  //         datar.append("tel", tel);
  //         datar.append("password", form.password);
  //         fetch(apiUrl + "/getID.php", {
  //           method: "post",
  //           body: datar,
  //         })
  //           .then((response) => {
  //             return response.json();
  //           })
  //           .then((data) => {
  //             let arr = data.split(",");
  //             localStorage.setItem("ID", arr[0]);
  //             localStorage.setItem("isActivated", arr[1]);
  //             console.log(arr[1]);
  //             if (arr[1] == 1) {
  //               Navigate("/welcome");
  //             } else {
  //               if (localStorage.MavieCode == "yescode") {
  //                 Navigate("/registrationComplete");
  //               } else {
  //                 localStorage.removeItem("isActivated", arr[1]);
  //                 Navigate("/registrationComplete");
  //               }
  //             }
  //           });
  //       } else {
  //         seterrorText("Неверные данные для входа");
  //       }
  //     });
  // };
  // 9108184700 reg auth
  const auther = () => {
    if (firstInput.current.value.length > 0) {
      let data = new FormData();
      data.append("id", tel);

      fetch(apiUrl + "/auth.php", {
        method: "post",
        body: data,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data === "0") {
            Navigate("/registrationPass");
          }
          if (data === "auth") {
            Navigate("/loginPass");
          }
        });
    }
  };
  const [buttonOpacity, setButtonOpacity] = useState(false);

  return <div></div>;
}
