import { filterProps } from "framer-motion";
import "./resourcesDownPart.css";
import { Link } from "react-router-dom";
import whiteSquare from "../../img/whiteSquare.png";
import { useEffect, useState, useRef } from "react";
import React from "react";
export default function ResourcesDownPart(props) {
  const LogoRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (LogoRef && LogoRef.current) {
        LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      }
    }, 500);
    setTimeout(() => {
      if (LogoRef && LogoRef.current) {
        LogoRef.current.style.animation = "wiggle 6s linear infinite";
        LogoRef.current.style.animationDelay = 0.7 * props.grader + "s";
      }
    }, 5000);
  }, []);
  console.log(props.src);
  const goPoocoin = () => {
    // window.open(props.src, "_blank");
    window.Telegram.WebApp.openLink(props.src);
  };
  return (
    <div className="resources_down_part_container">
      <div className="down_part_title">
        <p>{props.title}</p>
      </div>
      <div onClick={goPoocoin} className="whitesquare_box">
        <img
          className="whitesquare_box_img"
          ref={LogoRef}
          src={"https://cbt-version.ru/UltronAdminApi/img/" + props.imgSrc}
        />
      </div>
      <p onClick={goPoocoin} className="bottom_view_button">
        смотреть
      </p>
    </div>
  );
}
