import "./welcomePage.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ultronLogo from "../../img/UltronLogo.png";
import regCompleteArrow from "../../img/regCompleteArrow.png";
import welcomeImg from "../../img/welcomeImg.png";
import copyIcon from "../../img/copyIcon.png";
import AnimatedPage from "../animationPage";
import mansitastable from "../../img/mansitastable.png";
import welcomeImg1 from "../../img/welcomeImg1.png";
import welcomeImg3 from "../../img/welcomeImg3.png";
import welcomeImg4 from "../../img/welcomeImg4.png";
import welcomeStructures from "../../img/welcomeStructures.png";
import ModelViewer from "react-model-viewer";
import { apiUrl } from "../../const";
import React from "react";
export default function WelcomePage() {
  const innerHTML = useRef(null);
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const thirdInput = useRef(null);
  const fourthInput = useRef(null);
  const buttonIn = useRef(null);
  const forgotPass = useRef(null);
  const [idcop, setIdCop] = useState(localStorage.ID);
  const [activatedID, setActivatedID] = useState({
    id: "",
    activated: "",
  });
  const [doWeHaveFather, setDoWeHaveFather] = useState(false);
  const [fatherRefLink, setFatherRefLink] = useState("");
  useEffect(() => {
    const data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("tgId", localStorage.getItem("tgId"));
    fetch(apiUrl + "/getID.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data.split(",");
        setActivatedID({ id: arr[0], activated: arr[1] });

        if (arr[1] == "1") {
          // innerHTML.current.value = arr[0];
        }

        console.log(data);
        //localStorage.setItem("ID", arr[0]);
        localStorage.setItem("isActivated", arr[1]);
      });

    fetch(apiUrl + "/getFatherLink.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFatherRefLink(data);
      });
    fetch(apiUrl + "/getFather.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != "no") {
          setDoWeHaveFather(true);
        } else {
        }
      });
    setTimeout(() => {
      firstInput.current.style.transform = "scale(1)";
      secInput.current.style.transform = "scale(1)";
      thirdInput.current.style.transform = "scale(1)";
      fourthInput.current.style.transform = "scale(1)";
      buttonIn.current.style.transform = "scale(1)";
      forgotPass.current.style.transform = "scale(1)";
    }, 1000);
  }, []);
  console.log(activatedID);
  const hiddenInput = useRef(null);
  const copyTextBackgroundRef = useRef(null);
  const copytext = (e) => {
    innerHTML.current.select();
    document.execCommand("copy");
    copyTextBackgroundRef.current.style.background = "rgba(86, 26, 211, 0.4)";
    setTimeout(() => {
      copyTextBackgroundRef.current.style.background = "#EAE5F3";
    }, 1000);
  };
  useEffect(() => {
    setTimeout(() => {
      document.getElementsByClassName("welcome_title")[0].style = "opacity: 0";
    }, 3000);
    setTimeout(() => {
      document.getElementsByClassName("welcome_title")[0].style =
        "display: none";
    }, 4000);
    setTimeout(() => {
      document.getElementsByClassName("model")[0].style = "opacity:1";
    }, 4000);
  }, []);
  return (
    <AnimatedPage>
      <div className="welcome_page_container">
        <p className="welcome_title">добро пожаловать!</p>
        <div className="model">
          <model-viewer
            alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
            src="https://come-back-team.ru/model.glb"
            auto-rotate
            shadow-intensity="1"
            camera-controls
            disable-zoom
            disable-pan
            camera-orbit="-8.142746deg 85.967deg 0m"
            touch-action="pan-y"
          ></model-viewer>
        </div>

        {/* <ModelViewer type="gtlf" src={"./src/model.glb"} /> */}
        {/* <iframe width="100px" height="50px" src="https://modelviewer.dev/editor/view/?id=98557080803795160000"></iframe> */}
        <div className="welcome_links_box">
          <div className="welcome_links_left_column">
            <Link
              to="/databaseUltron"
              ref={firstInput}
              className="database_link"
            >
              <img
                className="database_link_img database_link_img1"
                src={welcomeImg1}
              />
              <p>база знаний ultron</p>
            </Link>
            <Link
              to="/databaseMavie"
              ref={thirdInput}
              className="database_link"
            >
              <img
                className="database_link_img database_link_img2"
                src={welcomeImg4}
              />
              <p>база знаний mavie</p>
            </Link>
          </div>
          <div className="welcome_links_column">
            <Link
              ref={fourthInput}
              to="/profileStructures"
              className="konkurses_link"
            >
              <img className="konkurses_link_img" src={welcomeStructures} />
              <p>структура</p>
            </Link>
            <Link ref={secInput} to="/documents" className="konkurses_link">
              <img className="konkurses_link_img" src={welcomeImg3} />
              <p>документы</p>
            </Link>
            <Link ref={buttonIn} to="/profile" className="konkurses_link">
              <img className="konkurses_link_img" src={mansitastable} />
              <div className="arrow_animated_on_welcome_page_box">
                <img
                  src={regCompleteArrow}
                  className="arrow_animated_on_welcome_page"
                />
              </div>
              <p>профиль</p>
            </Link>
          </div>
        </div>
        {activatedID.activated == "1" ? (
          <div ref={forgotPass} className="referal_box_welcome refDelay1">
            <p>ваш персональный код</p>
            <div className="referal_link_welcome">
              <div
                className="personal_ID"
                ref={copyTextBackgroundRef}
                style={
                  activatedID.activated == "1"
                    ? { opacity: "1" }
                    : { opacity: "0.5", pointerEvents: "none" }
                }
              >
                <div className="personal_ID_input_box">
                  код <input ref={innerHTML} value={idcop} readOnly></input>
                </div>
                <img className="copyIcon" onClick={copytext} src={copyIcon} />
              </div>
              {activatedID.activated == "1" ? (
                <p className="give_that_code">
                  Назовите этот код человеку которого вы хотите пригласить
                </p>
              ) : (
                <p className="give_that_code">
                  ID станет доступен после одобрения
                </p>
              )}
            </div>
          </div>
        ) : doWeHaveFather == true ? (
          <div ref={forgotPass} className="referal_box_welcome2 refDelay1">
            <p>присоединиться к команде MAVIE</p>
            <div className="referal_link_welcome2">
              <a className="referal_link_father" href={fatherRefLink}>
                Перейти
              </a>
              <p className="referal_link_father_text">
                пройдите регистрацию, <br /> если вы еще не являетесь
                <br /> партнером mavie.
              </p>
            </div>
          </div>
        ) : (
          <div ref={forgotPass} className="referal_box_welcome refDelay1">
            <p>ваш персональный код</p>
            <div className="referal_link_welcome">
              <div
                className="personal_ID"
                style={
                  activatedID.activated == "1"
                    ? { opacity: "1" }
                    : { opacity: "0.5", pointerEvents: "none" }
                }
              >
                <div className="personal_ID_input_box">
                  код{" "}
                  <input
                    ref={innerHTML}
                    value={localStorage.ID}
                    readOnly
                  ></input>
                </div>
                <img className="copyIcon" onClick={copytext} src={copyIcon} />
              </div>
              {activatedID.activated == "1" ? (
                <p className="give_that_code">
                  Назовите этот код человеку которого вы хотите пригласить
                </p>
              ) : (
                <p className="give_that_code">
                  ID станет доступен после одобрения
                </p>
              )}
            </div>
          </div>
        )}

        <div className="down_padding"></div>
        <textarea
          id="hidden_input"
          ref={hiddenInput}
          className="hidden_input"
        ></textarea>
      </div>
    </AnimatedPage>
  );
}
