import "./style.css";
import ArrowButtonBack from "../../components/arrowButtonStart/arrowButtonStart";
import AnimatedPage from "../animationPage";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import React from "react";
import arrow from "../../img/arrowToRight.png";
import StepsBlock from "../../components/stepsBlock/StepsBlock";
import WhereToStartPart from "../../components/whereToStartPart/whereToStartPart";
import ResourcesDownPart from "../../components/resourcesDownPart/resourcesDownPart";
import netBackground from "../../img/welcomeBackground.png";
import fullBackground from "../../img/databasebackground.png";
export default function SectionMavieContainer() {
  const Navigate = useNavigate();
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get("key");
  const logoRef = useRef(null);
  const [elements, setElements] = useState();
  const [pageInfo, setPageInfo] = useState();
  const getElements = () => {
    fetch(
      `https://cbt-version.ru/UltronAdminApi/Card_Mavie/?id=${myParam}&type=preview`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPageInfo(data);
      });
  };
  useEffect(() => {
    getElements();
    setTimeout(() => {
      logoRef.current.style.transform = "rotateZ(360deg) scale(1)";
    }, 300);
  }, []);
  console.log(pageInfo);
  // style={pageInfo&&pageInfo.type&&pageInfo.type.TYPE == "4" ? {backgroundImage: `url(${netBackground})`, backgroundSize: "cover"}:{backgroundImage: `url(${fullBackground})`, backgroundSize: "cover"}}
  return (
    <AnimatedPage>
      <div className="sections_main_container">
        <ArrowButtonBack />
        {pageInfo && pageInfo.type && pageInfo.type.TITLE ? (
          <p className="database_page_title">{pageInfo.type.TITLE}</p>
        ) : (
          <p className="database_page_title">Страница находится в разработке</p>
        )}
        {pageInfo &&
          pageInfo.type &&
          pageInfo.type.SUBTITLE &&
          pageInfo.type.SUBTITLE.length > 0 && (
            <p className="sections_undertitle">{pageInfo.type.SUBTITLE}</p>
          )}
        {pageInfo &&
          pageInfo.type &&
          pageInfo.type.IMG_SRC &&
          pageInfo.type.IMG_SRC.length > 0 && (
            <img
              className="sections_header_img"
              src={
                "https://cbt-version.ru/UltronAdminApi/img/" +
                pageInfo.type.IMG_SRC
              }
            />
          )}
        <div
          className="section_container_box"
          style={
            pageInfo && pageInfo.type && pageInfo.type.TYPE == "4"
              ? {
                  flexWrap: "wrap",
                  flexDirection: "unset",
                  alignItems: "unset",
                }
              : { flexDirection: "column" }
          }
        >
          {pageInfo &&
            pageInfo.cards.length > 0 &&
            pageInfo.cards.map((elem, index) => {
              if (
                pageInfo &&
                pageInfo.type.TYPE == "1" &&
                elem.VISIBILITY == 1
              ) {
                return (
                  <div
                    className="steps_block_box"
                    style={{ flexDirection: index % 2 != 0 && "row-reverse" }}
                    onClick={() => {
                      Navigate("/contentMavieContainer?key=" + elem.ID);
                    }}
                  >
                    <img
                      className="steps_block_img"
                      ref={logoRef}
                      src={
                        "https://cbt-version.ru/UltronAdminApi/img/" +
                        elem.IMG_SRC_PREVIEW
                      }
                    />
                    <div className="steps_block_info_box">
                      <p className="steps_block_number">{index + 1}</p>
                      <p className="steps_block_title">{elem.TITLE_PREVIEW}</p>
                    </div>
                  </div>
                );
              }
              if (
                pageInfo &&
                pageInfo.type.TYPE == "2" &&
                elem.VISIBILITY == 1
              ) {
                return (
                  <WhereToStartPart
                    onClick={() =>
                      Navigate("/contentMavieContainer?key=" + elem.ID)
                    }
                    key={index}
                    ID={elem.ID}
                    title={elem.TITLE_PREVIEW}
                    src={elem.SRC}
                    imgSrc={elem.IMG_SRC_PREVIEW}
                    index={index}
                  />
                );
              }
              if (pageInfo.type.TYPE == "3" && elem.VISIBILITY == 1) {
                return (
                  <div
                    onClick={() =>
                      Navigate("/contentMavieContainer?key=" + elem.ID)
                    }
                    className="presentation_button_box"
                    key={index}
                  >
                    <img
                      src={
                        "https://cbt-version.ru/UltronAdminApi/img/" +
                        elem.IMG_SRC_PREVIEW
                      }
                    />
                    <p>{elem.TITLE_PREVIEW}</p>
                    <div className="arrow_button_presentation_box">
                      <img src={arrow} />
                    </div>
                  </div>
                );
              }
              if (pageInfo.type.TYPE == "4" && elem.VISIBILITY == 1) {
                return (
                  <ResourcesDownPart
                    key={index}
                    ID={elem.ID}
                    imgSrc={elem.IMG_SRC_PREVIEW}
                    src={elem.SRC}
                    title={elem.TITLE_PREVIEW}
                  />
                );
              }
            })}
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
