import { useEffect, useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import title from "./title.svg";
import "./LandingPage.css";
import land1 from "./land1.png";
import ult from "./ult.svg";
import button_head from "./but_header.svg";
import land2 from "./land2.png";
import land3 from "./land3.png";
import six from "./six.svg";
import React from "react";
import land91 from "./land91.png";
import land92 from "./land92.png";
import land93 from "./land93.png";
import land4 from "./land4.png";
import land5 from "./land5.png";
import land6 from "./land6.png";
import land7 from "./land7.png";
import land8 from "./land8.png";
import land9 from "./land9.png";
import pres1 from "./pres1.png";
import pres2 from "./pres2.png";
import pres3 from "./pres3.png";
import copy from "./copy.svg";
import lend10 from "./land10.png";
import ultron from "./ultron.png";
import land11 from "./land11.png";
import lastLand from "./lastLend.png";
import mob1 from "./mobile1.png";
import mob2 from "./mob2.png";
import mob3 from "./mob3.png";
import mob4 from "./mob4.png";
import mob5 from "./mob5.png";
import mob6 from "./mob6.png";
import mob7 from "./mob7.png";
import mob8 from "./mob8.png";
import mob9 from "./mob9.png";
import mob10 from "./mob10.png";
import mob11 from "./mob11.png";
import mob12 from "./mob12.png";
import landb10 from "./land31.png";
import landb1 from "./land310.png";
import landb2 from "./land311.png";
import landb3 from "./land312.png";
import landb4 from "./land313.png";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import tg from "./tg.svg";
import tgt from "./tgt.svg";
export const LandingPage = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  var ms = new Date().getTime() + 86400000;
  var tomorrow = new Date(ms);
  const getTime = () => {
    const time = Date.parse(tomorrow) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };
  const MavieRef = useRef(null);
  const MavieRef1 = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => getTime(tomorrow), 1000);

    return () => clearInterval(interval);
  }, []);
  const copytext = (e) => {
    MavieRef.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  const copytext1 = (e) => {
    MavieRef1.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [slider, setSlider] = useState(0);
  const [textLink, setTextLink] = useState("");
  const [pop, setPop] = useState(false);
  const [imgPath, setImgPath] = useState("");
  const [link, setLink] = useState("");
  let url = new URL(document.location.href);
  let key = url.searchParams.get("key");
  const getParam = () => {
    let form = new FormData();
    form.append("ID", key);
    fetch("https://ultroninfobot.store/getInfoLanding.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.split(","));
      });
  };
  const fileName = useRef(null);

  function saveMe() {
    let form = new FormData();
    form.append("ID", key);
    if (text.length > 0) {
      form.append("text", text);
    } else {
      form.append("text", data[5]);
    }
    if (link.length > 0) {
      form.append("link", link);
    } else {
      form.append("link", data[6]);
    }
    if (textLink.length > 0) {
      form.append("textFio", textLink);
    } else {
      form.append("textFio", data[7]);
    }
    if (fileName.current.files.length > 0) {
      form.append("file", fileName.current.files[0]);
    } else {
      form.append("file", data[4]);
    }

    fetch("https://ultroninfobot.store/sendInfoLanding.php", {
      method: "POST",
      body: form,
    }).then((response) => {
      getParam();
      setLink("");
      setText("");
      setTextLink("");
      setPop(false);
    });
  }
  useEffect(() => {
    console.log("fff");
    getParam();
  }, []);

  return (
    <div className="landing__body">
      {document.body.clientWidth > 640 && (
        <div>
          <a
            href={data && data.length && data[6]}
            target="_blank"
            className="mainTgs"
          >
            <div className="tgs">
              <img src={tg} className="tg" alt="" />
              <img src={tgt} id="tgt" className="tgt" alt="" />
            </div>
            <div className="tgsText">
              <p>
                связаться <br />с куратором
              </p>
            </div>
          </a>

          {pop && (
            <div className="pop_settings">
              <img src={title} alt="" className="title_pop" />
              <div className="pop_set_body">
                <div>
                  <div className="desc_pop desc_more">
                    <h3>Поменять фотографию сайта</h3>
                    <p>
                      КОГДА ВЫ ПОДЕЛИТЕСЬ ССЫЛКОЙ ФОТОГРАФИЯ отобразится в
                      мессенджерах и соц.сетях
                    </p>
                  </div>
                  {data && data[4] && data[4].length > 0 ? (
                    <img
                      src={"https://ultroninfobot.store/" + data[4]}
                      alt=""
                      className="photo_red"
                    />
                  ) : (
                    <div className="wait_photo">Фото сейчас</div>
                  )}
                  <input
                    type="file"
                    name="name"
                    id="img__path"
                    ref={fileName}
                  />
                  <label htmlFor="img__path" className="img_load">
                    Загрузить
                  </label>
                </div>
                <div>
                  <p className="desc_pop">Ваше ФИО</p>
                  <input
                    type="text"
                    name="textLink"
                    placeholder="Введите текст до 60 символов"
                    value={textLink}
                    onChange={(e) => setTextLink(e.target.value)}
                  />
                </div>
                <div>
                  <p className="desc_pop">
                    Поменять описание блока “Ваш куратор”
                  </p>
                  <input
                    type="text"
                    name="text"
                    placeholder="Введите текст до 280 символов"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
                <div>
                  <p className="desc_pop">
                    Установите ссылку для кнопки “Написать”
                  </p>
                  <input
                    type="text"
                    name="link"
                    placeholder="ссылка на ваш аккаунт в телеграм"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="save__button" onClick={() => saveMe()}>
                  Сохранить
                </div>
              </div>
            </div>
          )}
          <div className="header_land">
            <img src={land1} className="land_img" alt="" />
            <Zoom>
              <img src={ult} alt="" className="ult_head" />
            </Zoom>
            <Zoom delay={150}>
              <div className="header_desc">
                простая, доступная возможность освоить и применять инновационные
                технологии и инструменты в быстро растущей сфере
                децентрализованных финансов и цифровых активов.
              </div>
            </Zoom>
            <Zoom delay={300}>
              <a
                className="registration"
                href={data && data.length > 0 && data[3]}
                target="_blank"
              >
                Зарегистрироваться
              </a>
            </Zoom>
            <a href="https://t.me/Ultroninfo_bot" target="_blank">
              <img src={button_head} className="button_head" alt="" />
            </a>
          </div>
          <div className="land2">
            <img src={land2} className="land_img" alt="" />
          </div>
          <div className="land3">
            <img src={land3} className="land_img" alt="" />
            <div className="dbd">
              <img src={landb1} alt="" />

              <img src={landb2} alt="" />
              <img src={landb3} alt="" />

              <img src={landb4} alt="" />
            </div>
            <img src={landb10} className="ddd" alt="" />
          </div>
          <div className="land4">
            <img src={land4} className="land_img" alt="" />
            <iframe
              className="youtube3"
              src="https://www.youtube.com/embed/KK2LNHfP7YE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="land5">
            <img src={land5} className="land_img" alt="" />
          </div>
          <div className="land6">
            <img src={land6} className="land_img" alt="" />
            <iframe
              className="youtube"
              src="https://www.youtube.com/embed/k7LNYQ9QnII"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="land7">
            <img src={land7} className="land_img" alt="" />
            <div className="lins">
              <div className="link_container">
                <p>Пригласительная ссылка</p>
                <div className="copy_link">
                  <p>
                    <input
                      readOnly
                      ref={MavieRef}
                      value={data && data.length > 0 && data[3]}
                    ></input>
                  </p>
                  <div className="copy_value" onClick={copytext}>
                    скопировать
                  </div>
                </div>
              </div>
              <div className="link_container">
                <p>На телефоне можно так же просканировать QR-код</p>
                <div
                  className="copy_link"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <QRCodeSVG value={data[3]} />
                  <p
                    style={{
                      width: "60%",
                      marginLeft: "1.5vw",
                      wordBreak: "normal",
                    }}
                  >
                    наведите камеру вашего телефона для сканирования QR-кода
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="land8">
            <img src={land8} className="land_img" alt="" />
            <iframe
              className="youtube2"
              src="https://www.youtube.com/embed/uEX1oZHHToo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="side_content">
              <img
                src={
                  data && data[4] && data[4].length > 0
                    ? `https://ultroninfobot.store/${data[4]}`
                    : data[2]
                }
                alt=""
              />
              <div>
                <h3>
                  {data && data[7] && data[7].length > 0 ? data[7] : data[0]}
                </h3>
                <p>
                  {data && data.length && data[5].length > 0
                    ? data[5]
                    : `Криптовалюта может быть сложным полем
для навигации, но 
вам не придется делать
это в одиночку. Наша команда здесь
для того, чтобы поддерживать друг друга
и помогать друг другу добиться успеха.
Так что если у вас что-то не получается,
напишите мне, и мы вместе все решим.`}
                </p>
                <a
                  href={data && data.length && data[6]}
                  target="_blank"
                  className="link__to"
                >
                  Написать
                </a>
              </div>
            </div>
          </div>
          <div className="land9">
            <img src={land9} className="land_img" alt="" />
            <div className="timer">
              <div>
                <p> {hours + ":" + minutes + ":" + seconds}</p>
                <span>успейте получить его</span>
              </div>
              <a
                href={data && data.length > 0 && data[3]}
                target="_blank"
                className="link__to"
              >
                Зарегистрироваться
              </a>
            </div>
            <div className="tel_img">
              <img src={land91} alt="" />
              <img src={land92} alt="" />
              <img src={land93} alt="" />
            </div>
          </div>
          <div className="land10">
            <img src={lend10} className="land_img" alt="" />
          </div>
          <div className="land11">
            <img src={land11} className="land_img" alt="" />
          </div>
          <div className="land12">
            <img src={lastLand} className="land_img" alt="" />
            <div className="personal__code">
              <h3>персональный код</h3>
              <div className="casul">
                <p>
                  id
                  <input className="kek" ref={MavieRef1} value={key}></input>
                </p>
                <div className="copy" onClick={() => copytext1()}>
                  скопировать
                  <img src={copy} alt="" />
                </div>
              </div>
            </div>
            <a
              className="copy reg"
              href="https://t.me/Ultroninfo_bot"
              target="_blank"
            >
              Зарегистрироваться <br />в приложении
            </a>
          </div>
        </div>
      )}
      {document.body.clientWidth < 640 && (
        <div>
          <a
            href={data && data.length && data[6]}
            target="_blank"
            className="mainTgs"
          >
            <div className="tgs">
              <img src={tg} className="tg" alt="" />
              <img src={tgt} id="tgt" className="tgt" alt="" />
            </div>
          </a>
          {pop && (
            <div className="pop_settings">
              <img src={title} alt="" className="title_pop" />
              <div className="pop_set_body">
                <div>
                  <div className="desc_pop desc_more">
                    <h3>Поменять фотографию сайта</h3>
                    <p>
                      КОГДА ВЫ ПОДЕЛИТЕСЬ ССЫЛКОЙ ФОТОГРАФИЯ отобразится в
                      мессенджерах и соц.сетях
                    </p>
                  </div>
                  {data && data[4] && data[4].length > 0 ? (
                    <img
                      src={"https://ultroninfobot.store/" + data[4]}
                      alt=""
                      className="photo_red"
                    />
                  ) : (
                    <div className="wait_photo">Фото сейчас</div>
                  )}
                  <input
                    type="file"
                    name="name"
                    id="img__path"
                    ref={fileName}
                  />
                  <label htmlFor="img__path" className="img_load">
                    Загрузить
                  </label>
                </div>
                <div>
                  <p className="desc_pop">Ваше ФИО</p>
                  <input
                    type="text"
                    name="textLink"
                    placeholder="Введите текст до 60 символов"
                    value={textLink}
                    onChange={(e) => setTextLink(e.target.value)}
                  />
                </div>
                <div>
                  <p className="desc_pop">
                    Поменять описание блока “Ваш куратор”
                  </p>
                  <input
                    type="text"
                    name="text"
                    placeholder="Введите текст до 280 символов"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
                <div>
                  <p className="desc_pop">
                    Установите ссылку для кнопки “Написать”
                  </p>
                  <input
                    type="text"
                    name="link"
                    placeholder="ссылка на ваш аккаунт в телеграм"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="save__button" onClick={() => saveMe()}>
                  Сохранить
                </div>
              </div>
            </div>
          )}
          <div className="header_land">
            <img src={mob1} className="land_img" alt="" />
            <Zoom>
              <div className="header_desc">
                простая, доступная возможность освоить и применять инновационные
                технологии и инструменты в быстро растущей сфере
                децентрализованных финансов и цифровых активов.
              </div>
            </Zoom>
            <Zoom>
              <a
                href={data && data.length > 0 && data[3]}
                target="_blank"
                className="registration"
              >
                Зарегистрироваться
              </a>
            </Zoom>

            <a href="https://t.me/Ultroninfo_bot" target="_blank">
              <img src={button_head} className="button_head" alt="" />
            </a>
          </div>
          <div className="land2">
            <img src={mob2} className="land_img" alt="" />
          </div>
          <div className="land3">
            <img src={mob3} className="land_img" alt="" />
          </div>
          <div className="land4">
            <img src={mob4} className="land_img" alt="" />
            <iframe
              className="youtube3"
              src="https://www.youtube.com/embed/KK2LNHfP7YE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="land5">
            <img src={mob5} className="land_img" alt="" />
          </div>
          <div className="land6">
            <img src={mob6} className="land_img" alt="" />
            <iframe
              className="youtube"
              src="https://www.youtube.com/embed/k7LNYQ9QnII"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="land7">
            <img src={mob7} className="land_img" alt="" />
            <div className="lins">
              <div className="link_container">
                <p>Пригласительная ссылка</p>
                <div className="copy_link">
                  <p>
                    <input
                      readOnly
                      ref={MavieRef}
                      value={data && data.length > 0 && data[3]}
                    ></input>
                  </p>
                  <div className="copy_value" onClick={copytext}>
                    скопировать
                  </div>
                </div>
              </div>
              <div className="link_container">
                <p>На телефоне можно так же просканировать QR-код</p>
                <div
                  className="copy_link"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <QRCodeSVG value={data[3]} />
                  <p
                    style={{
                      width: "60%",
                      marginLeft: "1.5vw",
                      wordBreak: "normal",
                    }}
                  >
                    наведите камеру вашего телефона для сканирования QR-кода
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="land8">
            <img src={mob8} className="land_img" alt="" />
            <iframe
              className="youtube2"
              src="https://www.youtube.com/embed/uEX1oZHHToo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="side_content">
            <img
              src={
                data && data.length > 0 && data[4].length > 0
                  ? `https://ultroninfobot.store/${data[4]}`
                  : data[2]
              }
              alt=""
            />
            <div>
              <h3>
                {" "}
                {data && data.length > 0 && data[7].length > 0
                  ? data[7]
                  : data[0]}
              </h3>
              <p>
                {" "}
                {data && data.length && data[5].length > 0
                  ? data[5]
                  : `Криптовалюта может быть сложным полем
для навигации, но 
вам не придется делать
это в одиночку. Наша команда здесь
для того, чтобы поддерживать друг друга
и помогать друг другу добиться успеха.
Так что если у вас что-то не получается,
напишите мне, и мы вместе все решим.`}
              </p>
              <a
                href={data && data.length && data[6]}
                target="_blank"
                className="link__to"
              >
                Написать
              </a>
            </div>
          </div>
          <div className="land9">
            <img src={mob9} className="land_img" alt="" />
            <div className="timer">
              <div>
                <p> {hours + ":" + minutes + ":" + seconds}</p>
                <span>успейте получить его</span>
              </div>
              <a
                href={data && data.length > 0 && data[3]}
                target="_blank"
                className="link__to"
              >
                Зарегистрироваться
              </a>
            </div>
          </div>
          <div className="land10">
            <img src={mob10} className="land_img" alt="" />
          </div>
          <div className="land11">
            <img src={mob11} className="land_img" alt="" />
          </div>
          <div className="land12">
            <img src={mob12} className="land_img" alt="" />
            <div className="personal__code">
              <h3>персональный код</h3>
              <div className="casul">
                <p>
                  id
                  <input className="kek" ref={MavieRef1} value={key}></input>
                </p>
                <div className="copy" onClick={() => copytext1()}>
                  скопировать
                  <img src={copy} alt="" />
                </div>
              </div>
            </div>
            <a
              className="copy reg"
              href="https://t.me/Ultroninfobot"
              target="_blank"
            >
              Зарегистрироваться <br />в приложении
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
