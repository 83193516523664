import "./profilePage.css";
import Burger from "../../components/burger/burger";
import { Link, useNavigate } from "react-router-dom";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import profilePhotoImg from "../../img/profilephoto.png";
import pencilImg from "../../img/pencil.png";
import QRarrows from "../../img/QRarrows.png";
import AnimatedPage from "../animationPage";
import { QRCodeSVG } from "qrcode.react";
import { apiUrl } from "../../const";
import buttonToProfileSettings from "../../img/buttonToProfileSettings.png";
import buttonToProfileStructures from "../../img/buttonToProfileStructures.png";
import { useEffect, useState, useRef } from "react";
import qrcodeplaceholderArrows from "../../img/qrcodeplaceholderArrows.png";
import React from "react";
import firstIcon from "./1.png";
import secondIcon from "./2.png";
import thirdIcon from "./3.png";
import settingIcon from "./4.png";
export default function ProfilePage() {
  const Navigate = useNavigate();
  const LogoRef = useRef(null);
  const forgot = useRef(null);
  const headerButtonLeft = useRef(null);
  const headerButtonRight = useRef(null);
  const isActivated = localStorage.isActivated;
  const [qrcodeLink, setQrcodelink] = useState("");
  const profileBoxRef1 = useRef(null);
  const profileBoxRef2 = useRef(null);
  const profileBoxRef3 = useRef(null);
  const profileBoxRef4 = useRef(null);
  const profileInputRef1 = useRef(null);
  const profileInputRef2 = useRef(null);
  const profileInputRef3 = useRef(null);
  const mavieRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      headerButtonLeft.current.style.transform = "scale(1)";
      headerButtonRight.current.style.transform = "scale(1)";
      profileBoxRef1.current.style.transform = "scale(1)";
      profileBoxRef2.current.style.transform = "scale(1)";
      profileBoxRef3.current.style.transform = "scale(1)";
      profileBoxRef4.current.style.transform = "scale(1)";
      forgot.current.style.transform = "scale(1)";
    }, 500);
    if (localStorage.imgPath) {
      setImgPath(localStorage.imgPath);
    }
    if (localStorage.referal) {
      setQrcodelink(localStorage.referal);
      mavieRef.current.value = localStorage.referal;
    }
  }, []);
  const fileName = useRef(null);
  const [imgPath, setImgPath] = useState("");
  const [saveOrCopy, setSaveOrCopy] = useState("");
  function onChange() {
    const data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("tgId", localStorage.getItem("tgId"));
    data.append("file", fileName.current.files[0]);
    fetch(apiUrl + "/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          localStorage.setItem("imgPath", data);
          setImgPath(data);
        }
        const datar = new FormData();
        datar.append("tel", localStorage.tel);
        datar.append("tgId", localStorage.getItem("tgId"));
        datar.append("link", data);
        fetch(apiUrl + "/updateImg.php", {
          method: "post",
          body: datar,
        });
      });
    console.log(fileName.current.files[0]);
  }
  const updateLink = () => {
    let data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("tgId", localStorage.getItem("tgId"));
    data.append("link", mavieRef.current.value);
    fetch(apiUrl + "/updateLink.php", {
      body: data,
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != "error") {
          localStorage.link = mavieRef.current.value;
        }
        console.log(data);
      });
  };
  const saveReferalQR = () => {
    console.log(mavieRef.current.value);
    localStorage.setItem("link", mavieRef.current.value);
    setQrcodelink(mavieRef.current.value);
    updateLink();
  };
  const copytext = (e) => {
    mavieRef.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  const copytext1 = (e) => {
    profileInputRef1.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  const copytext2 = (e) => {
    profileInputRef2.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  const copytext3 = (e) => {
    profileInputRef3.current.select();
    document.execCommand("copy");
    e.target.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      e.target.style.background = "rgba(59, 225, 0, 1)";
    }, 1000);
  };
  return (
    <AnimatedPage>
      <div className="profile_page_container">
        <ArrowButtonStart />

        <p className="profile_title">профиль</p>
        <div className="profile_header_buttons">
          <div className="header_button" ref={headerButtonLeft}>
            <Link to="/profileStructures">
              <img
                className="buttonToProfileSettings"
                src={buttonToProfileStructures}
              />
            </Link>
            <p className="buttonToName">структура</p>
          </div>
          <div ref={LogoRef} className="profile_photo_box">
            <img
              className="profile_photo"
              src={
                imgPath.length > 0
                  ? `https://ultroninfobot.store/${imgPath}`
                  : profilePhotoImg
              }
            />
            <form encType="multipart/form-data" action="URL" method="POST">
              <input
                type="file"
                name="file"
                onChange={onChange}
                ref={fileName}
                style={{ display: "none" }}
                id="avatar"
              ></input>
              <label htmlFor="avatar">
                <img className="pencil_photo_profile" src={pencilImg} />
              </label>
            </form>
          </div>
          <div className="header_button" ref={headerButtonRight}>
            <Link to="/profileSettings">
              <img
                src={buttonToProfileSettings}
                className="buttonToProfileSettings"
              />
            </Link>
            <p className="buttonToName">настройки</p>
          </div>
        </div>
        <div ref={profileBoxRef1} className="referal_box profileBoxRef1">
          <div className="profile_part_header">
            <img className="profile_icons" src={firstIcon} />
            <p className="referal_box_title">ваш сайт</p>
            <img
              className="profile_icons2"
              src={settingIcon}
              onClick={() => Navigate("/landingSettings")}
            />
          </div>
          <div className="referal_link">
            <input
              readOnly
              ref={profileInputRef1}
              className="referal_link_input"
              value={`https://ultroninfobot.store/landingPage?key=${localStorage.ID}`}
            ></input>

            <button onClick={copytext1}>скопировать</button>
          </div>
        </div>
        <div ref={profileBoxRef2} className="referal_box profileBoxRef2">
          <div className="profile_part_header">
            <img className="profile_icons" src={secondIcon} />
            <p className="referal_box_title">презентация</p>
          </div>
          <div className="referal_link">
            <input
              readOnly
              ref={profileInputRef2}
              className="referal_link_input"
              value={`https://ultroninfobot.store/botLand?key=${localStorage.ID}`}
            ></input>

            <button onClick={copytext2}>скопировать</button>
          </div>
        </div>
        <div ref={profileBoxRef3} className="referal_box profileBoxRef3">
          <div className="profile_part_header">
            <img className="profile_icons" src={thirdIcon} />
            <p className="referal_box_title">инструкции</p>
          </div>
          <div className="referal_link">
            <input
              readOnly
              ref={profileInputRef3}
              className="referal_link_input"
              value={`https://ultroninfobot.store/instructions`}
            ></input>

            <button onClick={copytext3}>скопировать</button>
          </div>
        </div>
        {/* <button onClick={() => Navigate("/landingSettings")}>
          Редактировать сайт
        </button> */}
        <div ref={profileBoxRef4} className="referal_box profileBoxRef4">
          <p className="referal_box_title2">ваша реферальная ссылка</p>
          <div className="referal_link">
            {localStorage.referal == "" ? (
              <input
                ref={mavieRef}
                className="referal_link_sec_input"
                placeholder={"Введите ссылку MAVIE"}
              ></input>
            ) : (
              <input
                readOnly
                ref={mavieRef}
                className="referal_link_input"
              ></input>
            )}
            {localStorage.referal == "" ? (
              <button onClick={saveReferalQR}>сохранить</button>
            ) : (
              <button onClick={copytext}>копировать</button>
            )}
          </div>
        </div>
        <div ref={forgot} className="qr_code_box">
          {qrcodeLink != "" ? (
            <QRCodeSVG value={qrcodeLink} />
          ) : (
            <div className="qrcode_placeholder_box">
              <div className="qrcode_placeholder">
                <b>Введите ссылку </b>
                <br /> и ваш QR код сформируется автоматически
              </div>
              <img
                className="qrcodeplaceholderArrows"
                src={qrcodeplaceholderArrows}
              />
            </div>
          )}

          <div className="your_qr_code">
            <p>ваш QR код</p>
            <img src={QRarrows} />
          </div>
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
