import { useEffect, useRef, useState } from "react";
import title from "./title.svg";
import React from "react";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
export default function LandingSettings() {
  const [data1, setData] = useState([]);
  const [text, setText] = useState("");
  const fileName = useRef(null);
  const [textLink, setTextLink] = useState("");
  const [pop, setPop] = useState(true);

  const [link, setLink] = useState("");
  const getParam = () => {
    let form = new FormData();
    form.append("ID", localStorage.ID);
    fetch("https://ultroninfobot.store/getInfoLanding.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.split(","));
      });
  };
  function saveMe() {
    let form = new FormData();
    form.append("ID", localStorage.ID);
    if (text.length > 0) {
      form.append("text", text);
    } else {
      form.append("text", data1[5]);
    }
    if (link.length > 0) {
      form.append("link", link);
    } else {
      form.append("link", data1[6]);
    }
    if (textLink.length > 0) {
      form.append("textFio", textLink);
    } else {
      form.append("textFio", data1[7]);
    }
    if (fileName.current.files.length > 0) {
      form.append("file", fileName.current.files[0]);
    } else {
      form.append("file", data1[4]);
    }

    fetch("https://ultroninfobot.store/sendInfoLanding.php", {
      method: "POST",
      body: form,
    }).then((response) => {
      getParam();
      setLink("");
      setText("");
      setTextLink("");
      setPop(false);
    });
  }
  useEffect(() => {
    getParam();
  }, []);
  useEffect(() => {
    if (data1 && data1.length > 0) {
      setLink(data1[6]);
      setText(data1[5]);
      setTextLink(data1[7]);
    }
  }, [data1]);
  console.log(data1);
  return (
    <div className="pop_settings">
      <ArrowButtonStart />
      <img src={title} alt="" className="title_pop" />
      <div className="pop_set_body">
        <div>
          <div className="desc_pop desc_more">
            <h3>Поменять фотографию сайта</h3>
          </div>
          {data1 && data1[4] && data1[4].length > 0 ? (
            <img
              src={"https://ultroninfobot.store/" + data1[4]}
              alt=""
              className="photo_red"
            />
          ) : (
            <div className="wait_photo">Фото сейчас</div>
          )}
          <input type="file" name="name" id="img__path" ref={fileName} />
          <label htmlFor="img__path" className="img_load">
            Загрузить
          </label>
        </div>
        <div>
          <p className="desc_pop">Ваше ФИО</p>
          <input
            type="text"
            name="textLink"
            placeholder="Введите текст до 60 символов"
            value={textLink}
            onChange={(e) => setTextLink(e.target.value)}
          />
        </div>
        <div>
          <p className="desc_pop">
            Поменять описание блока <br /> “Ваш куратор”
          </p>
          <input
            type="text"
            name="text"
            placeholder="Введите текст до 280 символов"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <div>
          <p className="desc_pop">Установите ссылку для кнопки “Написать”</p>
          <input
            type="text"
            name="link"
            placeholder="ссылка на ваш аккаунт в телеграм"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
        <div className="save__button" onClick={() => saveMe()}>
          Сохранить
        </div>
      </div>
    </div>
  );
}
