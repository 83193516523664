import "./style.css";
import ArrowButtonBack from "../../components/arrowButtonStart/arrowButtonStart";
import AnimatedPage from "../animationPage";
import { useState, useEffect, useRef } from "react";
import React from "react";
export default function ContentContainer() {
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get("key");
  const [arrData, setArrData] = useState([]);
  const getElements = () => {
    fetch(
      "https://cbt-version.ru/UltronAdminApi/Card/?id=" +
        myParam +
        "&type=content"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setArrData(data);
      });
  };

  useEffect(() => {
    getElements();
  }, []);
  function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }
  console.log(arrData);
  return (
    <AnimatedPage>
      <div className="content_container_box">
        <ArrowButtonBack />
        {arrData &&
          arrData.map((elem, index) => {
            if (elem.TYPE == "TITLE" && elem.VISIBILITY == 1) {
              return <p className="content_container_title">{elem.VALUE}</p>;
            }
            if (elem.TYPE == "TEXT1" && elem.VISIBILITY == 1) {
              return <p className="content_container_text1">{elem.VALUE}</p>;
            }
            if (elem.TYPE == "TEXT2" && elem.VISIBILITY == 1) {
              return <p className="content_container_text2">{elem.VALUE}</p>;
            }
            if (elem.TYPE == "LINK" && elem.VISIBILITY == 1) {
              return (
                <div className="link_date" key={index}>
                  <p>{elem.VALUE.header}</p>
                  <div>
                    <span>{elem.VALUE.text}</span>
                    <a
                      onClick={() =>
                        window.Telegram.WebApp.openLink(elem.VALUE.link_value)
                      }
                    >
                      ПЕРЕЙТИ
                    </a>
                  </div>
                </div>
              );
            }
            if (elem.TYPE == "LINK2" && elem.VISIBILITY == 1) {
              return (
                <div className="link_date" key={index}>
                  <div>
                    <span>{elem.VALUE.text}</span>
                    <a
                      onClick={() =>
                        window.Telegram.WebApp.openLink(elem.VALUE.link2_value)
                      }
                    >
                      ПЕРЕЙТИ
                    </a>
                  </div>
                </div>
              );
            }
            if (elem.TYPE == "TEXT3" && elem.VISIBILITY == 1) {
              return (
                <div className="content_container_text3_box">
                  <p className="content_container_text3_title">
                    {elem.VALUE.TITLE}
                  </p>
                  <p className="content_container_text3_text">
                    {elem.VALUE.VALUE}
                  </p>
                </div>
              );
            }
            if (elem.TYPE == "SUBTITLE" && elem.VISIBILITY == 1) {
              return <p className="content_container_subtitle">{elem.VALUE}</p>;
            }
            if (elem.TYPE == "IMG1" && elem.VISIBILITY == 1) {
              return (
                <img
                  className="content_container_img1"
                  src={
                    "https://cbt-version.ru/UltronAdminApi/img/" + elem.VALUE
                  }
                />
              );
            }
            if (elem.TYPE == "ELEM_LIST" && elem.VISIBILITY == 1) {
              return (
                <div className="content_container_elem_box">
                  <img
                    className="content_container_elem_img"
                    src={
                      "https://cbt-version.ru/UltronAdminApi/img/" +
                      elem.VALUE.IMG
                    }
                  />
                  <p className="content_container_elem_text">
                    {elem.VALUE.VALUE}
                  </p>
                </div>
              );
            }
            if (elem.TYPE == "IMG2" && elem.VISIBILITY == 1) {
              return (
                <div className="content_container_img2_box">
                  <img
                    className="content_container_img2"
                    src={
                      "https://cbt-version.ru/UltronAdminApi/img/" + elem.VALUE
                    }
                  />
                </div>
              );
            }
            if (elem.TYPE == "TABLE" && elem.VISIBILITY == 1) {
              return (
                <div className="content_container_table_box" key={index}>
                  {elem.VALUE.map((elem1, index1) => {
                    return (
                      <div
                        key={index1}
                        style={{
                          background: index1 % 2 == 1 && "#EFE8FF",
                          borderRadius:
                            index1 == elem.VALUE.length - 1 && "0 0 3vw 3vw",
                        }}
                        className="content_container_table_section"
                      >
                        <p>{elem1.COLUMN1}</p>
                        <span>{elem1.COLUMN2}</span>
                      </div>
                    );
                  })}
                </div>
              );
            }
            if (elem.TYPE == "VIDEO" && elem.VISIBILITY == 1) {
              let src1 = youtube_parser(elem.VALUE);
              return (
                <div className="content_container_video_box">
                  <iframe
                    src={"https://www.youtube.com/embed/" + src1}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              );
            }
          })}
      </div>
    </AnimatedPage>
  );
}
