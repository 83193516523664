import React, { useState } from "react";
import "./style.css";
import alm from "./alm.svg";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import Slide from "react-reveal/Slide";
import razd from "./razd.svg";
import butt from "./butt.svg";
import video from "./video.png";
import mobraz from "./mobraz.svg";
import mobvideo from "./mobvideo.png";
import close from "./close.svg";
import tg from "../landingPage/tg.svg";
import tgt from "../landingPage/tgt.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const videos = [
  "https://www.youtube.com/embed/rF_fHeEddag",
  "https://www.youtube.com/embed/mC7EKp0-GdA",
  "https://www.youtube.com/embed/3fhyi-_BS_I",
  "https://www.youtube.com/embed/dtYa9-A0UQQ",
  "https://www.youtube.com/embed/r5oDVmPXQCI",
  "https://www.youtube.com/embed/hIxMBAT0uhw",
  "https://www.youtube.com/embed/8V4O2hB5z5M",
  "https://www.youtube.com/embed/7ghebn1v_Rc",
  "https://www.youtube.com/embed/5ywqYTA7W28",
  "https://www.youtube.com/embed/67cK80v6wBA",
  "https://www.youtube.com/embed/cm_ULq60glM",
  "https://www.youtube.com/embed/WnI_g4MUu1c",
  "https://www.youtube.com/embed/DS2JGgUY_Bc",
  "https://www.youtube.com/embed/HCC7orFk0AU",
  "https://www.youtube.com/embed/A31h739D31g",
  "https://www.youtube.com/embed/4AcCeq16jro",
];
const titles = [
  "ultron foundation - команда достижения",
  "быстрый рост и достижения ultron",
  "институцианальные инвесторы",
  "мощная экосистема ultron",
  "ULX scan - мощный блокчейн исследователь",
  "ULTRON swap - простой в использовании криптообмен",
  "Дорожная карта ultron",
  "ivana tattoo art nft",
  "lottoday - самая большая криптолотерея в мире",
  "flipme - приложения и дебетовая карта. онлайн транзакции & fiat",
  "RE SHAPE СОЦИАЛЬНАЯ ПЛАТФОРМА",
  "R N B W - NFT маркетплейс",
  "Ballrs - футбольная метавселенная",
  "lending & borrowing - платформа кредитования и заимствования",
  "launch pad - краудфандинговая платформа",
  "Представляем ключ к экосистеме ultron",
];
export default function BotLand() {
  const [pop, setPop] = useState(false);
  const [data, setData] = useState();
  let url = new URL(document.location.href);
  let key = url.searchParams.get("key");
  console.log(data);
  const getParam = () => {
    let form = new FormData();
    form.append("ID", key);
    fetch("https://ultroninfobot.store/getInfoLanding.php", {
      method: "POST",
      body: form,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data.split(","));
      });
  };
  useEffect(() => {
    getParam();
  }, []);
  return (
    <div className="bot_land">
      <a
        href={data && data.length && data[6]}
        target="_blank"
        className="mainTgs"
      >
        <div className="tgs">
          <img src={tg} className="tg" alt="" />
          <img src={tgt} id="tgt" className="tgt" alt="" />
        </div>
        <div
          className="tgsText"
          style={document.body.clientWidth < 640 ? { display: "none" } : {}}
        >
          <p>
            связаться <br />с куратором
          </p>
        </div>
      </a>
      {pop && (
        <div className="pop_bot">
          <img
            src={close}
            alt=""
            className="closeme"
            onClick={() => {
              setPop(false);
            }}
          />
          <div className="pop_bot_body">
            <iframe
              className="youtubeBot1"
              src="https://www.youtube.com/embed/WU_6vPY87ew"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      )}
      <div className="header_bot">
        <div className="header_bot_body">
          <img
            src={butt}
            alt=""
            className="butt_sm"
            onClick={() => {
              setPop(true);
            }}
          />
          <div className="header_title">
            <Zoom>
              <h1>Узнай</h1>
            </Zoom>
            <Zoom delay={300}>
              <span>какой блокчейн после</span>
            </Zoom>
          </div>
          <Zoom delay={500}>
            <h2 className="bot_sub">Eth, Solana, Bnb</h2>
          </Zoom>
          <Zoom delay={700}>
            <div className="x1_bot">сделает иксы!</div>
          </Zoom>{" "}
          <Zoom delay={900} cascade>
            <p className="product">продукты:</p>

            <ul className="bot_list">
              <li>
                <img src={alm} alt="" />
                <p>футбольная мета-вселенная</p>
              </li>
              <li>
                <img src={alm} alt="" />
                <p>Первая в мире соц. сеть на блокчейне</p>
              </li>
              <li>
                <img src={alm} alt="" />
                <p>Децентрализованная биржа</p>
              </li>
              <li>
                <img src={alm} alt="" />
                <p>собственные NFT</p>
              </li>
              <li>
                <img src={alm} alt="" />
                <p>сотни приложений на блокчейне ultron</p>
              </li>
            </ul>
          </Zoom>
          <Zoom delay={1000}>
            <div className="bot_buttons">
              <Link
                target="_blank"
                to="/instructions"
                className="link_instruction"
              >
                инструкция
              </Link>
              <div>
                <a
                  target="_blank"
                  href={
                    data && data.length > 0 && data[2]
                      ? `https://t.me/${data[2]}`
                      : ""
                  }
                  style={
                    document.body.clientWidth < 800 ? { display: "none" } : {}
                  }
                >
                  консультация
                </a>
                <a
                  target="_blank"
                  href={data && data.length > 0 && data[3] ? data[3] : ""}
                >
                  регистрация
                </a>
              </div>
            </div>
          </Zoom>
        </div>
      </div>
      {videos &&
        document.body.clientWidth > 800 &&
        videos.length > 0 &&
        videos.map((em, index) => {
          return (
            <div className="bot_video">
              <img src={razd} alt="" className="razd" />
              <p className="hinst">{titles[index]}</p>
              <img src={video} className="vid" alt="" />
              <iframe
                className="youtubeBot"
                src={em}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      {videos &&
        document.body.clientWidth < 800 &&
        videos.length > 0 &&
        videos.map((em, index) => {
          return (
            <div className="bot_video">
              <img src={mobraz} alt="" className="razd" />
              <p className="hinst">{titles[index]}</p>
              <img src={mobvideo} className="vid" alt="" />
              <iframe
                className="youtubeBot"
                src={em}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
    </div>
  );
}
