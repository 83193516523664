import "./registrationPage.css";
import ultronLogo from "../../img/UltronLogo.png";
import ultronHelp from "../../img/help.svg";
import videoplaceholder from "./videoplaceholder.png";
import React from "react";
import { Link } from "react-router-dom";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import helpIcon from "../../img/helpIcon.png";
import { apiUrl } from "../../const";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
export default function RegistrationInfoPage() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    fio: "",
    code: "",
    IDmavie: "",
    referal: "",
  });
  const [tel, setTel] = useState("+");
  const [fioEntry, setFioEntry] = useState(
    JSON.parse(localStorage.getItem("tgObj")).first_name +
      " " +
      JSON.parse(localStorage.getItem("tgObj")).last_name
  );
  const [codeEntry, setCodeEntry] = useState("");
  const [IDmavieEntry, setIDmavieEntry] = useState("");
  const [referalEntry, setReferalEntry] = useState("");
  const [errorText, seterrorText] = useState(false);
  const [regDone, setRegDone] = useState(false);
  const [percentBar, setPercentBar] = useState(0);
  const [codeKurator, setCodeKurator] = useState("");
  const checkReg = () => {
    let tg = window.Telegram.WebApp;
    let formData = new FormData();
    formData.append("id", tg.initDataUnsafe.user.id);
    //formData.append("id", "215394377");

    fetch(apiUrl + "/authMain.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data != 0) {
          Navigate("/registrationWaiter");
        }
      });
  };
  useEffect(() => {
    checkReg();
  }, []);
  const registration = () => {
    let formData = new FormData();
    formData.append("code", codeEntry);
    fetch(apiUrl + "/checkCode.php", {
      method: "POST",
      body: formData,
    })
      .then((respose) => {
        return respose.json();
      })
      .then((data) => {
        if (data == "0") {
          setCodeKurator("Неверный код");
        } else {
          let arr = data.split(",");
          localStorage.setItem("nasTel", arr[1]);
          localStorage.setItem("nasFio", arr[0]);
          localStorage.setItem("fioReg", fioEntry);
          localStorage.setItem("codeReg", codeEntry);
          localStorage.setItem("fio", fioEntry);
          localStorage.setItem("code", codeEntry);
          localStorage.setItem("MavieCode", "nocode");
          let datar = new FormData();
          datar.append("FIO", fioEntry);
          datar.append("tel", tel);
          datar.append("mavie", IDmavieEntry);
          datar.append("ref", referalEntry);
          datar.append("code", codeEntry);
          datar.append("id", localStorage.getItem("tgId"));
          datar.append(
            "usernameTg",
            JSON.parse(localStorage.getItem("tgObj")).username
          );
          fetch(apiUrl + "/registration.php", {
            method: "POST",
            body: datar,
          })
            .then((respose) => {
              return respose.json();
            })
            .then((data) => {
              if (data == "Ok") {
                localStorage.tel = tel;
                const datars = new FormData();
                datars.append("name", fioEntry);
                datars.append("phone", localStorage.loginerTel);
                datars.append("code", codeEntry);
                datars.append("mavie", IDmavieEntry);
                datars.append("referal", referalEntry);
                datars.append(
                  "usernameTg",
                  localStorage.getItem("tgObj").username
                );
                datars.append("id", localStorage.getItem("tgId"));
                fetch(apiUrl + "/bot.php", {
                  method: "post",
                  body: datars,
                });
                setRegDone(true);
                let formdata2 = new FormData();
                formdata2.append("tel", localStorage.loginerTel);
                formdata2.append("code", codeEntry);
                formdata2.append("id", localStorage.getItem("tgId"));
                fetch("https://ultroninfobot.store/getTel.php", {
                  method: "POST",
                  body: formdata2,
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((data) => {
                    localStorage.setItem("telKurator", data);
                  });
              } else {
                console.log(data);
              }
            });
        }
      });
  };
  function checkFields() {
    if (
      fioEntry.length > 0 &&
      codeEntry.length > 0 &&
      IDmavieEntry.length > 0 &&
      referalEntry.length > 0 &&
      tel.length > 2
    ) {
      registration();
    } else {
      seterrorText(true);
    }
  }
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const thirdInput = useRef(null);
  const fourthInput = useRef(null);
  const fifthInput = useRef(null);
  const buttonIn = useRef(null);
  const buttonReg = useRef(null);
  const helpRef = useRef(null);
  const popupRef = useRef(null);
  var telRef = "";
  useEffect(() => {
    setTimeout(() => {
      // LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      firstInput.current.style.transform = "scale(1)";

      buttonIn.current.style.transform = "scale(1)";
      thirdInput.current.style.transform = "scale(1)";
      fourthInput.current.style.transform = "scale(1)";
      fifthInput.current.style.transform = "scale(1)";
      //buttonReg.current.style.transform = "scale(1)";
      helpRef.current.style.transform = "scale(1)";
    }, 500);
  }, []);
  return (
    <AnimatedPage>
      <div className="registration_page_container">
        <div className="welcome_popup_box" ref={popupRef}>
          <div className="welcome_popup">
            <p className="welcome_popup_title">РАДЫ ВИДЕТЬ ВАС!</p>
            <p className="welcome_popup_text">
              Для регистрации в приложении заполните все поля внимательно!
            </p>
            <div
              className="welcome_popup_button"
              onClick={() => {
                popupRef.current.style = "opacity: 0";
                setTimeout(() => {
                  popupRef.current.style.display = "none";
                }, 500);
              }}
            >
              заполнить
            </div>
          </div>
        </div>
        {errorText ? (
          <div className="error_popup_box">
            <div className="error_popup">
              <p className="error_popup_title">Внимание!</p>
              <p className="error_popup_text">Заполните все поля</p>
              <div
                className="error_popup_button"
                onClick={() => {
                  seterrorText(false);
                }}
              >
                закрыть
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {regDone ? (
          <div className="welcome_popup_box" ref={popupRef}>
            <div className="welcome_popup">
              <p className="regdone_popup_title">Спасибо за регистрацию!</p>
              <p className="regdone_popup_text1">
                Ваша заявка находится на рассмотрении
              </p>
              <p className="regdone_popup_text2">
                в самое ближайшее время вы сможете воспользоваться приложением
                @ULTRONINFOBOT
              </p>
              <div
                className="welcome_popup_button"
                onClick={() => {
                  Navigate("/registrationWaiter");
                }}
              >
                закрыть
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <p className="registration_title">
          посмотрите короткое видео это важно!!!
        </p>
        <img className="videoplaceholder" src={videoplaceholder} />
        <p className="videoplaceholder_bottom">всего 1,5 минуты</p>
        <a
          ref={helpRef}
          href="https://t.me/Ultroninfo_bot"
          alt=""
          className="help_button_on_register"
        >
          <img src={helpIcon} />
          <p>помощь</p>
        </a>

        <div className="input_tel">
          <input
            ref={firstInput}
            type="text"
            name="fio"
            className="first_input full_inputs"
            value={fioEntry}
            onChange={(e) => setFioEntry(e.target.value)}
            placeholder="Ваше имя"
          ></input>
        </div>
        <div className="invited_id_box">
          <input
            ref={thirdInput}
            type="text"
            name="code"
            className="sec_input full_inputs"
            value={codeEntry}
            onChange={(e) => setCodeEntry(e.target.value)}
            placeholder="Код куратора"
          ></input>
        </div>
        <PhoneInput
          value={tel}
          onChange={setTel}
          placeholder="ТЕЛЕФОН привязанный к telegram"
        />
        <div className="id_mavie">
          <input
            ref={fourthInput}
            type="text"
            name="IDmavie"
            className="third_input full_inputs"
            value={IDmavieEntry}
            onChange={(e) => setIDmavieEntry(e.target.value)}
            placeholder="Ваш ID mavie"
          ></input>
        </div>
        <div className="referal_link_mavie">
          <input
            ref={fifthInput}
            type="text"
            name="referal"
            className="fourth_input full_inputs"
            value={referalEntry}
            onChange={(e) => setReferalEntry(e.target.value)}
            placeholder="реферальная ссылка mavie"
          ></input>
        </div>
        <div className="percentfill_box">
          <div
            className="percentinfo_bar"
            style={{
              width:
                (Number(fioEntry.length > 0) +
                  Number(codeEntry.length > 0) +
                  Number(IDmavieEntry.length > 0) +
                  Number(referalEntry.length > 0) +
                  Number(tel.length > 2)) *
                  20 +
                "%",
            }}
          ></div>
          <p>заполнено</p>
          <p>
            {(Number(fioEntry.length > 0) +
              Number(codeEntry.length > 0) +
              Number(IDmavieEntry.length > 0) +
              Number(referalEntry.length > 0) +
              Number(tel.length > 2)) *
              20}
            %
          </p>
        </div>
        <div
          ref={buttonIn}
          className="button_registration_on"
          onClick={() => checkFields()}
        >
          зарегистрироваться
        </div>
        {/* <Link to="/">
          <button ref={buttonReg} className="button_back_to_login">
            вернуться к входу
          </button>
        </Link> */}
      </div>
    </AnimatedPage>
  );
}
