import "./presentationUltron.css";
import videoPlaceholder from "../../../img/videoPlaceholder.png";
import ArrowButtonPresentation from "./arrowButtonPresentation";
import AnimatedPage from "../../animationPage";
import React from "react";
export default function PresentationPromotionNewbies() {
  return (
    <AnimatedPage>
      <div className="presentations_container">
        <ArrowButtonPresentation />
        <div className="presentation_titles">Промоушены для первопроходцев</div>
        <iframe
          width="99%"
          height="198"
          src="https://www.youtube.com/embed/AnMbW5adJTg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="presentations_text">
          При покупке хаба с монетами Ulx <br /> вы получаете еще долю в мета{" "}
          <br /> вселенной которую готовит <br /> компания Devla{" "}
        </p>
      </div>
    </AnimatedPage>
  );
}
