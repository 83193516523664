import "./wantToJoinBranch.css";
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin";
import AnimatedPage from "../animationPage";
import videoPlaceholder from "../../img/videoPlaceholder.png";
import wantToJoinRegister1 from "../../img/wantToJoinRegister1.png";
import wantToJoinRegister2 from "../../img/wantToJoinRegister2.png";
import wantToJoinRegister3 from "../../img/wantToJoinRegister3.png";
import React from "react";
export default function WantToJoinRegister() {
  return (
    <AnimatedPage>
      <div className="want_to_join_containers">
        <ArrowButtonWantToJoin />
        <p className="want_to_join_parts_title">регистрация mavie.global</p>
        <iframe
          width="99%"
          height="190"
          src="https://www.youtube.com/embed/MJ2CygX70XI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="want_to_join_parts_undertitle">
          В этом видео вы узнаете:
        </p>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinRegister1} />
          <p>
            Как зарегистрироваться <br /> в кабинет Mavie
          </p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinRegister2} />
          <p>
            Как купить монеты Ulx Hub, со
            <br /> стейкингом до 0.9 в месяц + бонус Nft
          </p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinRegister3} />
          <p>
            Где находится партнёрская ссылка
            <br /> для приглашений
          </p>
        </div>
      </div>
    </AnimatedPage>
  );
}
