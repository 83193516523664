import "./loginPage.css";
import ultronLogo from "../../img/Ultron3d.svg";
import React from "react";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../const";
import helpIcon from "../../img/helpIcon.png";
import PhoneInput from "react-phone-number-input";
export default function LoginPassPage() {
  const Navigate = useNavigate();
  const popupRef = useRef(null);
  const [tel, setTel] = useState("");
  const [pass, setPass] = useState("");
  const helpRef = useRef(null);
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  useEffect(() => {
    if (localStorage.loginerTel) {
      setTel(localStorage.loginerTel);
    }
    setTimeout(() => {
      if (LogoRef.current) {
        LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      }
      // firstInput.current.style.transform = "scale(1)";
      secInput.current.style.transform = "scale(1)";
      // buttonIn.current.style.transform = "scale(1)";
      // // forgotPass.current.style.transform="scale(1)"
      // buttonReg.current.style.transform = "scale(1)";
      helpRef.current.style.transform = "scale(1)";
    }, 500);
  }, []);
  const authMain = () => {
    let data = new FormData();
    data.append("tel", tel);
    data.append("password", pass);
    fetch(apiUrl + "/authMain.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data.split(",");
        localStorage.setItem("name", arr[0]);
        localStorage.setItem("imgPath", arr[1]);
        localStorage.setItem("referal", arr[3]);
        localStorage.setItem("link", arr[4]);
        localStorage.setItem("tel", tel);
        localStorage.setItem("password", pass);
        console.log(arr);
        if (arr[5] == 1) {
          Navigate("/welcome");
        } else if (arr[5] == 0) {
          Navigate("/registrationWaiter");
        }
      });
  };
  return (
    <AnimatedPage>
      <div className="login_page_container">
        <div className="error_popup_box" ref={popupRef}>
          <div className="error_popup">
            <p className="error_popup_title">
              Рады видеть <br /> вас снова!
            </p>
            <p className="loginpass_popup_text">
              Для входа в приложение введите свой пароль
            </p>
            <button
              className="error_popup_button"
              onClick={() => {
                popupRef.current.style = "opacity: 0";
                setTimeout(() => {
                  popupRef.current.style.display = "none";
                }, 500);
              }}
            >
              закрыть
            </button>
          </div>
        </div>
        <p className="login_title">
          войдите <br /> через свой аккаунт телеграм
        </p>
        <a
          ref={helpRef}
          href="https://t.me/Ultroninfo_bot"
          alt=""
          className="help_button_on_login"
        >
          <img src={helpIcon} />
          <p>помощь</p>
        </a>
        <div className="inputs_box">
          <PhoneInput
            ref={firstInput}
            value={tel}
            onChange={setTel}
            placeholder="ТЕЛЕФОН привязанный к telegram"
          />
          <input
            ref={secInput}
            type="text"
            name="fio"
            className="first_input full_inputs"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            placeholder="введите ваш пароль"
          ></input>
        </div>
        <div
          onClick={() => {
            authMain();
          }}
          className="button_continue_login"
        >
          войти
        </div>
        <img className="ultron_logo" ref={LogoRef} src={ultronLogo} />
      </div>
    </AnimatedPage>
  );
}
