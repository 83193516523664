import "./whyJoinUltron.css";
import ArrowButtonWhereToStart from "../arrowButtonWhereToStart/arrowButtonWhereToStart";
import AnimatedPage from "../../animationPage";
import whyJoinCircle from "../../../img/whyJoinCircle.png";
import reasonProjectBoxImg from "../../../img/reasonProjectBoxImg.png";
import React from "react";
export default function WhyJoinUltron() {
  return (
    <AnimatedPage>
      <div className="why_join_ultron_container">
        <ArrowButtonWhereToStart />
        <p className="why_join_ultron_title">ПОЧЕМУ СТОИТ ЗАЙТИ В ULTRON?</p>
        <p className="why_join_ultron_undertitle">
          Любые крупные структуры строятся <br /> на старте проекта, когда о нем
          знают <br /> мало людей.{" "}
        </p>
        <p className="why_join_ultron_text">
          <b>Доходы в первые полугода можно делать максимально большие,</b>{" "}
          особенно в бинарном маркетинге как на блокчейне Ultron + правильный
          подход.
        </p>
        <p className="why_join_ultron_text">
          На старте в подобных бинарных маркетингах
          <br /> - огромные переливы по от активных
          <br /> лидеров. Как правило когда человек
          <br /> получает результат в виде перелива, то ему
          <br /> легко дальше работать в проекте.
        </p>
        <p className="why_join_ultron_text">
          Тут же переливы даже без проплаты, а это очень мощный инструмент о
          котором поговорим на нашем маркетинговом канале.
        </p>
        <img className="whyJoinCircle" src={whyJoinCircle} />
        <p className="why_join_ultron_text">
          Проанализировав проект Ultron наша
          <br /> команда увидела большую{" "}
          <b>
            {" "}
            идею, которая
            <br /> даст блокчейну Ultron жизнь на десятилетия.
          </b>
        </p>
        <div className="reason_project_box">
          <img src={reasonProjectBoxImg} className="reasonProjectBoxImg" />
          <p className="why_join_ultron_text_reason">
            <b>Суть проекта:</b> Блокчейн Ultron 1-го
            <br /> уровня, такой как Eth, Solana, Cordano,
            <br /> Polygon, Fantom, Bnb на котором можно
            <br /> делать сотни разных разработок.
          </p>
        </div>
        <p className="why_join_ultron_text">
          <b>Обратите внимание команда более 32 человек,</b>
          <br /> также есть медийные личности.
        </p>
        <p className="why_join_ultron_text">
          Сейчас мы выходим из крипто зимы и люди
          <br /> активно начинают заходить в крипто
          <br /> проекты. Это проект поможет проработать
          <br /> годы, а точнее как в компании заложено -<br />{" "}
          <b>
            стейкинг на 5 лет, это создаёт ещё 1<br /> источник дохода на годы.
          </b>
        </p>
        <div className="scroll_down_container">
          <div className="scroll_down">Листайте вниз</div>
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
