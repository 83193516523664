import "./promotionHowItWorks.css";
import AnimatedPage from "../../animationPage";
import ArrowButtonPromotion from "../ArrowButtonPromotion";
import videoPlaceholder from "../../../img/videoPlaceholder.png";
import React from "react";
export default function PromotionHowItWorks() {
  return (
    <AnimatedPage>
      <div className="promotion_how_it_works_container">
        <ArrowButtonPromotion />
        <p className="promotion_how_it_works_title">
          КАК РАБОТАЕТ АВТОМАТИЗАЦИЯ
        </p>
        <iframe
          width="99%"
          height="180"
          src="https://www.youtube.com/embed/bfAXaC8qB1k"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="promotion_how_it_works_text">
          Это первый вебинар описывающий <br /> что вы получаете в нашей
          команде.
        </p>
      </div>
    </AnimatedPage>
  );
}
