import "./registrationPage.css";
import ultronLogo from "../../img/UltronLogo.png";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import helpIcon from "../../img/helpIcon.png";
import PhoneInput from "react-phone-number-input";
import React from "react";
export default function RegistrationPassPage() {
  const Navigate = useNavigate();
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const buttonIn = useRef(null);
  // const forgotPass = useRef(null)
  const buttonReg = useRef(null);
  const helpRef = useRef(null);
  const popupRef = useRef(null);
  const [tel, setTel] = useState("");
  const [pass, setPass] = useState("");
  useEffect(() => {
    if (localStorage.loginerTel) {
      setTel(localStorage.loginerTel);
    }
    setTimeout(() => {
      if (LogoRef.current) {
        LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      }

      firstInput.current.style.transform = "scale(1)";
      // secInput.current.style.transform = "scale(1)";
      // buttonIn.current.style.transform = "scale(1)";
      // // forgotPass.current.style.transform="scale(1)"
      // buttonReg.current.style.transform = "scale(1)";
      helpRef.current.style.transform = "scale(1)";
    }, 500);
  }, []);
  const continueReg = () => {
    localStorage.setItem("password", pass);
    Navigate("/registrationInfo");
  };
  return (
    <AnimatedPage>
      <div className="registration_pass_page_container">
        <div className="welcome_popup_box" ref={popupRef}>
          <div className="welcome_popup">
            <p className="welcome_popup_title">рады видеть вас!</p>
            <p className="welcome_popup_text">
              для регистрации придумайте запоминающийся пароль
            </p>
            <div
              className="welcome_popup_button"
              onClick={() => {
                popupRef.current.style = "opacity: 0";
                setTimeout(() => {
                  popupRef.current.style.display = "none";
                }, 500);
              }}
            >
              Ввести пароль
            </div>
          </div>
        </div>
        <p className="login_title">
          зарегистрируйтесь через свой аккаунт телеграм
        </p>
        <a
          ref={helpRef}
          href="https://t.me/Ultroninfo_bot"
          alt=""
          className="help_button_on_login"
        >
          <img src={helpIcon} />
          <p>помощь</p>
        </a>
        <div className="inputs_box">
          <PhoneInput
            ref={firstInput}
            value={tel}
            onChange={setTel}
            placeholder="ТЕЛЕФОН привязанный к telegram"
          />
          <div className="input_tel">
            {/* <div className="already_exists_phone">{fioErrorText}</div> */}
            <input
              ref={firstInput}
              type="text"
              name="fio"
              className="first_input full_inputs"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              placeholder="введите ваш пароль"
            ></input>
          </div>
        </div>
        <div
          onClick={() => {
            continueReg();
          }}
          className="button_continue_login"
        >
          зарегистрироваться
        </div>
        <img className="ultron_logo" ref={LogoRef} src={ultronLogo} />
      </div>
    </AnimatedPage>
  );
}
