import "./registrationPage.css";
import regCompleteArrow from "../../img/regCompleteArrow.png";
import structuresListCopy from "../../img/structuresListCopy.png";
import secStepRegManIcon from "../../img/secStepRegManIcon.png";
import secStepRegPhoneIcon from "../../img/secStepRegPhoneIcon.png";
import videoPlaceholder from "../../img/videoPlaceholder.png";
import { useRef, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { apiUrl } from "../../const";
import AnimatedPage from "../animationPage";
export default function RegistrationWaiterPage() {
  const Navigate = useNavigate();
  const copyInputHolder = useRef();
  const copyInputButton = useRef();
  const hiddenBox = useRef();
  const mavieInputRef = useRef();
  const mavieButtonRef = useRef();
  const [mavieError, setMavieError] = useState("");
  const [buttonOpacity, setButtonOpacity] = useState(false);
  const [form, setForm] = useState({
    tel: "",
    password: "",
    fio: "",
    code: "",
    mavie: "",
  });
  var isActivated;
  if (localStorage.isActivated == "") {
    isActivated = localStorage.isActivated;
  }

  useEffect(() => {
    setForm({
      ...form,
      tel: localStorage.telReg,
      password: localStorage.passwordReg,
      fio: localStorage.fioReg,
      code: localStorage.codeReg,
    });
  }, []);
  const handleChange = (event) => {
    switch (event.target.name) {
      case "mavie":
        setForm({
          ...form,
          mavie: event.target.value,
        });
        break;
    }
    if (mavieInputRef.current.value.length >= 2) {
      setButtonOpacity(true);
    } else {
      setButtonOpacity(false);
    }
  };
  const copyText = () => {
    copyInputHolder.current.select();
    document.execCommand("copy");
    copyInputButton.current.style.background = "rgba(86, 26, 211, 0.6)";
    setTimeout(() => {
      copyInputButton.current.style.background = "rgba(59, 225, 0, 1)";
    }, 500);
  };
  const sendIt = () => {
    if (mavieInputRef.current.value.length >= 2) {
      setMavieError("");
      const data = new FormData();
      data.append("tel", form.tel);
      data.append("mavie", form.mavie);
      fetch(apiUrl + "/registrationСomplete.php", {
        method: "post",
        body: data,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setMavieError(data);
          if (data == "Ok") {
            setMavieError("");
            localStorage.setItem("MavieCode", "yescode");
            hiddenBox.current.style.opacity = "1";
            hiddenBox.current.style.pointerEvents = "all";
          }
        });
    } else {
      setMavieError("Заполните поле");
    }
  };
  const goToLoginAfterRegComplete = () => {
    localStorage.removeItem("telReg");
    Navigate("/");
  };

  const messageKurator = () => {
    let formdata = new FormData();
    formdata.append("tel", "+499139639712");
    formdata.append("tgId", localStorage.getItem("tgId"));
    formdata.append("code", "86");

    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://ultroninfobot.store/getTel.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };
  const checkReg = () => {
    let tg = window.Telegram.WebApp;
    let formData = new FormData();
    formData.append("id", tg.initDataUnsafe.user.id);
    //formData.append("id", "215394377");

    fetch(apiUrl + "/authMain.php", {
      method: "post",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let arr = data.split(",");
        if (arr[5] == 1) {
          Navigate("/");
        }
      });
  };
  useEffect(() => {
    checkReg();
  }, []);
  return (
    <AnimatedPage>
      <div className="registration_complete_container">
        <p className="registration_complete_title">
          Ваш аккаунт находится <br /> в процессе подтверждения
        </p>
        {/* {isActivated == "" ? (
          <p className="registration_complete_title">
            Ваш аккаунт находится в процессе подтверждения
          </p>
        ) : (
          <div>
            <p className="registration_complete_title">
              Для завершения процесса активации бота
            </p>
            <div className="input_tel">
              <div className="already_exists_phone">{mavieError}</div>
              <input
                onChange={handleChange}
                ref={mavieInputRef}
                name="mavie"
                value={form.mavie}
                type="text"
                className="sec_step_reg_mavie_input"
                placeholder="ВВЕДИ ВАШ ID В MAVIE"
              ></input>
            </div>
            <button
              style={buttonOpacity ? { opacity: "1" } : { opacity: "0.5" }}
              onClick={sendIt}
              className="sec_step_reg_send_it"
            >
              отправить
            </button>
            <div
              ref={hiddenBox}
              className="hidden_thank_for_registration_box_background"
            >
              <div className="hidden_thank_for_registration_box">
                <p className="hidden_thank_for_registration_title">
                  спасибо за регистрацию!
                </p>
                <p className="hidden_thank_for_registration_text">
                  ваша заявка находится <br /> на рассмотрении
                </p>
                <div
                  onClick={goToLoginAfterRegComplete}
                  className="hidden_thank_for_registration_button"
                >
                  вернуться к входу
                </div>
              </div>
            </div>
            <div className="dont_have_mavieID_box">
              <p className="dont_have_mavieID_title">
                если у вас нет id в mavie свяжитесь с вашим наставником
              </p>
              <p className="dont_have_mavieID_text">
                Присоединяйтесь и начните максимально эффективно зарабатывать c
                ULTRON & MAVIE <br /> уже сейчас!
              </p>
              <div className="dont_have_mavieID_inputs_box">
                <div className="dont_have_mavieID_inviter_phone_name_box">
                  <div className="secStepRegIconsBox">
                    <img className="secStepRegIcons" src={secStepRegManIcon} />
                  </div>
                  <input
                    value={localStorage.getItem("nasFio")}
                    className="dont_have_mavieID_inviter_name_phone"
                    readOnly
                  ></input>
                </div>
                <div className="dont_have_mavieID_inviter_phone_name_box">
                  <div className="secStepRegIconsBoxPhone">
                    <img
                      className="secStepRegIconsPhone"
                      src={secStepRegPhoneIcon}
                    />
                  </div>
                  <input
                    ref={copyInputHolder}
                    value={localStorage.getItem("nasTel")}
                    className="dont_have_mavieID_inviter_name_phone"
                    readOnly
                  ></input>
                  <div className="dont_have_mavieID_inviter_phone_copy_button_box">
                    <input
                      ref={copyInputButton}
                      onClick={copyText}
                      className="dont_have_mavieID_inviter_phone_copy_button"
                      readOnly
                      value={"скопировать"}
                    ></input>
                    <img
                      className="structuresListCopy"
                      src={structuresListCopy}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        <p className="call_tech_support">
          Если в течении 12 часов вы
          <br /> не получили ответ от
          <br /> наставника, свяжитесь с
          <br />
          поддержкой сервиса
          {/* <span>(Ссылка телеграм чата)</span> */}
        </p>
        <a
          href="https://t.me/Ultroninfo_bot"
          alt=""
          className="send_it_reg_sec_step"
        >
          написать в поддержку
        </a>
        <div className="send_it_reg_kurator" onClick={messageKurator}>
          написать куратору
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
