import "./analyticsPage.css";
import ArrowButtonGoBack from "../../components/arrowButtonGoBack/arrowButtonGoBack";
import Burger from "../../components/burger/burger";
import birgesImg from "../../img/birgesImg.png";
import AnimatedPage from "../animationPage";
import React from "react";
import analyticsImg from "../../img/analyticsImg.png";
import analyticsIcon1 from "../../img/analyticsIcon1.png";
import analyticsIcon2 from "../../img/analyticsIcon2.png";
import analyticsIcon3 from "../../img/analyticsIcon3.png";
import analyticsIcon4 from "../../img/analyticsIcon4.png";
import analyticsIcon5 from "../../img/analyticsIcon5.png";
import analyticsIcon6 from "../../img/analyticsIcon6.png";
import analyticsIcon7 from "../../img/analyticsIcon7.png";
import analyticsIcon8 from "../../img/analyticsIcon8.png";
import { useEffect, useState, useRef } from "react";
export default function AnalyticsPage() {
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const first = useRef(null);
  const sec = useRef(null);
  const buttonIn = useRef(null);
  const button = useRef(null);
  const forgotPass = useRef(null);
  const forgot = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      firstInput.current.style.transform = "rotateZ(360deg) scale(1)";
      secInput.current.style.transform = "rotateZ(360deg) scale(1)";
      first.current.style.transform = "rotateZ(360deg) scale(1)";
      sec.current.style.transform = "rotateZ(360deg) scale(1)";
      buttonIn.current.style.transform = "rotateZ(360deg) scale(1)";
      button.current.style.transform = "rotateZ(360deg) scale(1)";
      forgotPass.current.style.transform = "rotateZ(360deg) scale(1)";
      forgot.current.style.transform = "rotateZ(360deg) scale(1)";
    }, 500);
    setTimeout(() => {
      firstInput.current.style.animation = "wiggle 6s linear infinite";
      secInput.current.style.animation = "wiggle 6s linear infinite";
      secInput.current.style.animationDelay = "0.7s";
      first.current.style.animation = "wiggle 6s linear infinite";
      first.current.style.animationDelay = "1.4s";
      sec.current.style.animation = "wiggle 6s linear infinite";
      sec.current.style.animationDelay = "2.1s";
      buttonIn.current.style.animation = "wiggle 6s linear infinite";
      buttonIn.current.style.animationDelay = "2.8s";
      button.current.style.animation = "wiggle 6s linear infinite";
      button.current.style.animationDelay = "3.5s";
      forgotPass.current.style.animation = "wiggle 6s linear infinite";
      forgotPass.current.style.animationDelay = "4.2s";
      forgot.current.style.animation = "wiggle 6s linear infinite";
      forgot.current.style.animationDelay = "4.9s";
    }, 5000);
  }, []);

  const goPoocoin = () => {
    window.open("https://digitalcoinprice.com/coins/ultron", "_blank");
  };
  const goSuperex = () => {
    window.open("https://coinmarketcap.com/currencies/ultron/", "_blank");
  };
  const goUniswap = () => {
    window.open(
      "https://www.coingecko.com/ru/Криптовалюты/ultron#markets",
      "_blank"
    );
  };
  const goPancake = () => {
    window.open("https://nomics.com/assets/ulxbep20-ultron-bep-20", "_blank");
  };
  const goUltSwap = () => {
    window.open("https://www.binance.com/en/price/ultron", "_blank");
  };
  const goMessari = () => {
    window.open("https://messari.io/asset/ultron", "_blank");
  };
  const goCoinbase = () => {
    window.open("https://www.coinbase.com/ru/", "_blank");
  };
  const goCrypto = () => {
    window.open("https://crypto.com/", "_blank");
  };
  return (
    <AnimatedPage>
      <div className="analytics_page_container">
        <ArrowButtonGoBack />
        <Burger />
        <p className="analytics_title">аналитика</p>
        <img ref={LogoRef} className="analyticsImg" src={analyticsImg} />
        <div className="analytics_row">
          <div onClick={goPoocoin} className="analytics_part_container">
            <div className="analytics_part_titleOne">
              <p>DigitalCoin Price</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img
                className="firstDelayRef"
                ref={firstInput}
                src={analyticsIcon1}
              />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
          <div onClick={goSuperex} className="analytics_part_container">
            <div className="analytics_part_titleOne">
              <p>CoinMar- ketCap</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img
                className="secDelayRef"
                ref={secInput}
                src={analyticsIcon2}
              />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
          <div onClick={goUniswap} className="analytics_part_container">
            <div className="analytics_part_titleOne">
              <p>CoinGecko</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img className="thirdDelayRef" ref={first} src={analyticsIcon3} />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
        </div>
        <div className="analytics_row">
          <div onClick={goPancake} className="analytics_part_container">
            <div className="analytics_part_titleTwo">
              <p>Nomics</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img className="fourthDelayRef" ref={sec} src={analyticsIcon4} />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
          <div onClick={goUltSwap} className="analytics_part_container">
            <div className="analytics_part_titleTwo">
              <p>Binance</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img
                className="fifthDelayRef"
                ref={buttonIn}
                src={analyticsIcon5}
              />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
          <div onClick={goMessari} className="analytics_part_container">
            <div className="analytics_part_titleTwo">
              <p>Messari</p>
            </div>
            <a href="" className="analytics_whitesquare_box">
              <img
                className="sixthDelayRef"
                ref={button}
                src={analyticsIcon6}
              />
            </a>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
        </div>
        <div className="analytics_row">
          <div onClick={goCoinbase} className="analytics_part_containerBig">
            <div className="analytics_part_titleTwo">
              <p>Coinbase</p>
            </div>
            <div className="analytics_whitesquare_box">
              <img
                className="seventhDelayRef"
                ref={forgotPass}
                src={analyticsIcon7}
              />
            </div>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
          <div onClick={goCrypto} className="analytics_part_containerHalfbig">
            <div className="analytics_part_titleTwo">
              <p>Crypto</p>
            </div>
            <div className="analytics_whitesquare_box">
              <img className="eithDelayRef" ref={forgot} src={analyticsIcon8} />
            </div>
            <a href="" className="analytics_part_bottom_button">
              смотреть
            </a>
          </div>
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
