import arrowImg from "../../../img/arrowback.png";
import React from "react";
export default function ArrowButtonPresentation() {
  const WindowBack = () => {
    window.history.back();
  };
  return (
    <div className="arrow_button_go_back_container" onClick={() => WindowBack}>
      <div>
        <img src={arrowImg} />
        <p>презентации</p>
      </div>
    </div>
  );
}
