import "./burgerMenu.css";
import burgerIcon from "../../img/menuburger2.png";
import { Link } from "react-router-dom";
import UltronMiniLogo from "../../img/ultronMiniLogo.png";
import React, { useState } from "react";
export default function BurgerMenu(props) {
  const setMenuOpener = props.setMenuOpener;
  const burgerRef = props.burgerRef;
  const [menu, setMenu] = useState(false);
  const closeMenu = () => {
    window.document.getElementsByClassName("burger_menu_container")[0].style =
      "background: rgba(0, 0, 0, 0); width: 0vw";
    window.document.getElementsByClassName("burger_menu_links")[0].style.right =
      "-50vw";
    burgerRef.current.style.transform = "rotateZ(0deg)";
    setMenuOpener(false);
  };
  const closeMenuSec = () => {
    localStorage.clear();
    window.document.getElementsByClassName("burger_menu_container")[0].style =
      "background: rgba(0, 0, 0, 0); width: 0vw";
    window.document.getElementsByClassName("burger_menu_links")[0].style.right =
      "-50vw";
    burgerRef.current.style.transform = "rotateZ(0deg)";
    setMenuOpener(false);
    localStorage.setItem("preloaderPlayed", "true");
  };
  document.addEventListener("click", (event) => {
    if (event.target.className == "burger_menu_container") {
      closeMenu();
    }
  });
  return (
    <div className="burger_menu_container">
      <div className="burger_menu_links">
        <Link to="/welcome" onClick={closeMenu}>
          <li className="right_links">
            главная <div className="list_dot"></div>
          </li>
        </Link>

        <li className="right_links lir" onClick={() => setMenu(!menu)}>
          база знаний <div className="list_dot"></div>
        </li>
        <div
          className="pod_menu"
          style={menu ? { height: "fit-content" } : { height: 0 }}
        >
          <Link to="/databaseUltron" onClick={closeMenu}>
            <li className="right_links">
              ultron <div className="list_dot"></div>
            </li>
          </Link>
          <Link to="/databaseMavie" onClick={closeMenu}>
            <li className="right_links">
              mavie <div className="list_dot"></div>
            </li>
          </Link>
        </div>
        <Link to="/profileStructures" onClick={closeMenu}>
          <li className="right_links">
            структура <div className="list_dot"></div>
          </li>
        </Link>
        <Link to={"/documents"} onClick={closeMenu}>
          <li className="right_links">
            документы <div className="list_dot"></div>
          </li>
        </Link>
        <Link to="/profile" onClick={closeMenu}>
          <li className="right_links">
            профиль <div className="list_dot"></div>
          </li>
        </Link>

        <Link to="/" onClick={closeMenuSec}>
          <li className="right_links">
            выйти <div className="list_dot"></div>
          </li>
        </Link>
        {/* <img src={UltronMiniLogo} alt="" style={{ width: "100%" }} /> */}
      </div>
    </div>
  );
}
