import "./whereToStart.css";
import ArrowButtonDataBase from "../../components/arrowButtonDataBase/arrowButtonDataBase";
import WhereToStartPart from "../../components/whereToStartPart/whereToStartPart";
import AnimatedPage from "../animationPage";
import whatStart1 from "../../img/whatStart1.png";
import whatStart2 from "../../img/whatStart2.png";
import whatStart3 from "../../img/whatStart3.png";
import whatStart4 from "../../img/whatStart4.png";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import React from "react";

export default function WhereToStart() {
  const LogoRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
    }, 500);
  }, []);

  var requestArr = {
    sectionsFirst: [
      {
        id: 1,
        title: "Почему стоит зайти в Ultron?",
        img: whatStart2,
        className: "start_part_img2",
        link: "/whyJoinUltron",
        delay: "0.05s",
      },
      {
        id: 2,
        title: "Презентации Ultron",
        img: whatStart3,
        className: "start_part_img3",
        link: "/presentationUltron",
        delay: "0.10s",
      },
      {
        id: 3,
        title: "Мне интересно, хочу попробовать",
        img: whatStart4,
        className: "start_part_img4",
        link: "/wantToJoin",
        delay: "0.15s",
      },
    ],
  };
  return (
    <AnimatedPage>
      <div className="where_to_start_container">
        <ArrowButtonDataBase />
        <p className="where_to_start_title">с чего начать?</p>
        <p className="where_to_start_undertitle">
          <b>Изучите внимательно,</b> ведь старт всегда самое важное!
        </p>
        <div className="where_to_start_part_container">
          <img ref={LogoRef} className="start_part_imgBig" src={whatStart1} />
          <div className="start_part_info">
            <p>Посмотрите видео об Автоматизации</p>
            <Link to="/videoAutomatization" className="watch_video">
              смотреть
            </Link>
          </div>
          <div className="start_part_right_year"></div>
        </div>
        {requestArr.sectionsFirst.map((requestArrs) => (
          <WhereToStartPart
            key={requestArrs.id}
            href={requestArrs.href}
            delay={requestArrs.delay}
            title={requestArrs.title}
            img={requestArrs.img}
            className={requestArrs.className}
            link={requestArrs.link}
          />
        ))}
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
