import "./wantToJoinBranch.css";
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin";
import AnimatedPage from "../animationPage";
import videoPlaceholder from "../../img/videoPlaceholder.png";
import React from "react";
export default function WantToJoinFAQ() {
  return (
    <AnimatedPage>
      <div className="want_to_join_containers want_to_join_containersFAQ">
        <ArrowButtonWantToJoin />
        <p className="want_to_join_club_balance_title">
          Частые вопросы и ответы
        </p>
        <div className="question_box">
          <p className="white_question">
            Отправил Usdt на свой кошелёк по сети BEP20,
            <br /> но до кошелька они не дошли. Как в этом случае
            <br /> поступить? Адрес проверил, все сходится.
          </p>
          <p className="green_answer">Ответ: Тут</p>
        </div>
        <div className="question_box">
          <p className="purple_question">
            Какие условия становления валидатором?
          </p>
          <p className="white_answer">Ответ: Иметь от 1 млн. монет ULX.</p>
        </div>
        <div className="question_box">
          <p className="white_question">Где посмотреть историю выводов?</p>
          <p className="green_answer">Ответ: Тут</p>
        </div>
        <div className="question_box">
          <p className="purple_question">
            Как поставить двухфакторную верификацию
          </p>
          <div className="white_answer">
            <p>Этот сервис рекомендуем к использованию двухфакторок </p>
            <div className="white_answer_button">здесь</div>
          </div>
        </div>
        <div className="question_box">
          <p className="purple_question">
            Как вывести деньги на биржу без верификации?
          </p>
          <div className="white_answer_with_button">
            <p className="white_answer_with_button_p">
              Регистрируемся на Bibox
            </p>
            <a
              className="white_answer_button"
              href="https://www.bibox.com/ru/login/register"
            >
              здесь
            </a>
          </div>
          <div className="white_answer_with_button">
            <p className="white_answer_with_button_p">
              Кошелёк на биржу Trc20 для пополнения с кабинета Mavie
            </p>
            <div className="white_answer_button">здесь</div>
          </div>
          <div className="white_answer_with_button">
            <p className="white_answer_with_button_p">Вывод с Ultron видео</p>
            <a
              className="white_answer_button"
              href="https://youtu.be/-aONo-ljcuU"
            >
              здесь
            </a>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
