import "./presentationUltron.css";
import videoPlaceholder from "../../../img/videoPlaceholder.png";
import ArrowButtonPresentation from "./arrowButtonPresentation";
import howMakeMoney1 from "../../../img/howMakeMoney1.png";
import howMakeMoney2 from "../../../img/howMakeMoney2.png";
import vosklMark from "../../../img/VosklMark.png";
import AnimatedPage from "../../animationPage";
import React from "react";
export default function PresentationHowMakeMoney() {
  return (
    <AnimatedPage>
      <div className="presentations_containerBig">
        <ArrowButtonPresentation />
        <div className="presentation_titles">Как зарабатывать USDT в Mavie</div>
        <iframe
          width="100%"
          height="198"
          src="https://www.youtube.com/embed/pXeRSh9LhY0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <p className="presentations_text">
          Вы узнаете что такое Staking Hub, <br /> ULX, а так же доходности по
          годам
        </p>
        <div className="how_make_money_text">
          <img src={howMakeMoney1} />
          <p>
            <b>Что такое Staking Hub</b> - это
            <br /> распределённый блок, который
            <br /> приносит фиксированные пассивные
            <br /> вознаграждения ежедневно в монете
            <br /> ULX в течении 5 лет.
          </p>
        </div>
        <div className="how_make_money_text">
          <img src={howMakeMoney2} />
          <p>
            <b>Стоимость ULX</b> - это прогноз основанный
            <br /> на обычных экономических факторах,
            <br /> влияющих на цену криптовалюты, как
            <br /> любой другой валюты или инвестиции:
            <br /> продложения и спрос, общественные
            <br /> настроения, новостной цикл, изменения
            <br /> на рынке, дефицит и др.
          </p>
        </div>
        <div className="prognoz_money_cost">
          <img src={vosklMark} />
          <p>
            Прогноз цен нельзя понимать как гарантию рыночно зависимых ценовых
            колебаний.
          </p>
        </div>
        <p className="how_make_money_text_bold">
          Доход по стейкингу уменьшается каждый год:
        </p>
        <div className="date_money_income_box">
          <div className="date_money_income_row">
            <p className="date_money_income_row_left">1 Июня 2022</p>
            <p className="date_money_income_row_right">0.2% в день</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left">1 Июня 2023</p>
            <p className="date_money_income_row_right">0.1% в день</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left">1 Июня 2024</p>
            <p className="date_money_income_row_right">0.05% в день</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left">1 Июня 2025</p>
            <p className="date_money_income_row_right">0.025% в день</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left">1 Июня 2026</p>
            <p className="date_money_income_row_right">0.0125% в день</p>
          </div>
        </div>
        <p className="how_make_money_text_common">
          Авто стейкинг - генерация сложного <br /> процента. Если автостейкинг
          будет включён то<span> в первый год вы получите 107%.</span>{" "}
        </p>
        <p className="how_make_money_text_bold">
          разблокировка монет <br /> по годам
        </p>
        <div className="date_money_income_box_downer">
          <div className="date_money_income_row">
            <p className="date_money_income_row_left_downer">1 год</p>
            <p className="date_money_income_row_right_downer">30%</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left_downer">2 год</p>
            <p className="date_money_income_row_right_downer">25%</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left_downer">3 год</p>
            <p className="date_money_income_row_right_downer">20%</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left_downer">4 год</p>
            <p className="date_money_income_row_right_downer">15%</p>
          </div>
          <div className="date_money_income_row">
            <p className="date_money_income_row_left_downer">5 год</p>
            <p className="date_money_income_row_right_downer">10%</p>
          </div>
        </div>
        <div className="scroll_down_container">
          <div className="scroll_down">Листайте вниз</div>
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
