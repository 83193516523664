import "./arrowButtonStart.css";
import arrowImg from "../../img/arrowback.png";
import { Link } from "react-router-dom";
import React from "react";
import Burger from "../burger/burger";
export default function ArrowButtonStart() {
  const WindowBack = () => {
    window.history.back();
  };
  return (
    <div className="arrow_button_back_container">
      <div onClick={WindowBack}>
        <img src={arrowImg} />
        <p>назад</p>
      </div>
      {document.location.pathname != "/databaseUltron" &&
        document.location.pathname != "/databaseMavie" && <Burger />}
    </div>
  );
}
