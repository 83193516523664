import "./wantToJoinBranch.css";
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin";
import AnimatedPage from "../animationPage";
import videoPlaceholder from "../../img/videoPlaceholder.png";
import wantToJoinLC1 from "../../img/wantToJoinLC1.png";
import wantToJoinLC2 from "../../img/wantToJoinLC2.png";
import wantToJoinLC3 from "../../img/wantToJoinLC3.png";
import wantToJoinLC6 from "../../img/wantToJoinLC4.png";
import wantToJoinLC5 from "../../img/wantToJoinLC5.png";
import wantToJoinLC4 from "../../img/wantToJoinLC6.png";
import React from "react";
export default function WantToJoinLC() {
  return (
    <AnimatedPage>
      <div className="want_to_join_containers">
        <ArrowButtonWantToJoin />
        <p className="want_to_join_parts_title">обзор кабинета mavie.global</p>
        <iframe
          width="99%"
          height="165"
          src="https://www.youtube.com/embed/N8IWxekmMAA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="want_to_join_parts_undertitle">
          В этом видео вы узнаете:
        </p>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC1} />
          <p>
            Куда прописать кошелек
            <br /> для вывода Usdt
          </p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC2} />
          <p>Какие виды балансов есть в кабинете</p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC3} />
          <p>Сколько делается вывод средств</p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC4} />
          <p>Как добавить в Metamask Usdt BEP 20</p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC5} />
          <p>
            Сколько можно заработать за первые три для работы в проекте Ultron
          </p>
        </div>
        <div className="want_to_join_register_steps">
          <img src={wantToJoinLC6} />
          <p>О метавселенной Ultron</p>
        </div>
      </div>
    </AnimatedPage>
  );
}
