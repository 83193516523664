import "./promotionPresentation.css";
import AnimatedPage from "../../animationPage";
import ArrowButtonPromotion from "../ArrowButtonPromotion";
import presentationScreen1 from "../../../img/presentationScreen1.png";
import presentationScreen2 from "../../../img/presentationScreen2.png";
import presentationArrowLeft from "../../../img/presentationArrowLeft.png";
import presentationArrowRight from "../../../img/presentationArrowRight.png";
import { apiUrl } from "../../../const";
import React from "react";
export default function PromotionPresentation() {
  const goPoocoin = () => {
    window.open(apiUrl + "/ULTRONPresentation.pdf", "_blank");
  };
  return (
    <AnimatedPage>
      <div className="promotion_presentation_container">
        <ArrowButtonPromotion />
        <p className="promotion_presentation_title">презентация</p>
        <img
          onClick={goPoocoin}
          className="presentationScreen"
          src={presentationScreen1}
        />
        <img
          onClick={goPoocoin}
          className="presentationScreen"
          src={presentationScreen2}
        />
        <a
          onClick={goPoocoin}
          href=""
          className="full_screen_button_presentation"
        >
          полноэкранный режим
        </a>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
