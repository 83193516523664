import "./promotionSellingPosts.css";
import AnimatedPage from "../../animationPage";
import ArrowButtonPromotion from "../ArrowButtonPromotion";
import sellingPostsImg from "../../../img/sellingPostsImg.png";
import arrowToRight from "../../../img/arrowToRight.png";
import React from "react";
export default function PromotionSellingPosts() {
  return (
    <AnimatedPage>
      <div className="promotion_selling_posts_container">
        <ArrowButtonPromotion />
        <p className="promotion_selling_posts_title">продающие посты</p>
        <img src={sellingPostsImg} />
        <div className="selling_posts_links_box">
          <div className="selling_posts_links_doubled">
            <div className="selling_posts_links_doubled_box">
              <p>
                Маркетин-
                <br />
                говый пост
              </p>
              <a
                href="https://t.me/c/1775208220/4"
                className="arrow_button_selling_posts_links_doubled_box"
              >
                <img src={arrowToRight} />
              </a>
            </div>
            <div className="selling_posts_links_doubled_box">
              <p>
                Маркетин-
                <br />
                говый пост
              </p>
              <a
                href="https://t.me/c/1775208220/5"
                className="arrow_button_selling_posts_links_doubled_box"
              >
                <img src={arrowToRight} />
              </a>
            </div>
          </div>
          <div className="selling_posts_links_solo_box">
            <p>Пост: Подготовка к запуску проекта</p>
            <a
              href="https://t.me/c/1775208220/6"
              className="arrow_button_selling_posts_links_doubled_box"
            >
              <img src={arrowToRight} />
            </a>
          </div>
          <div className="selling_posts_links_solo_box">
            <p>Тезисная презентация голосом</p>
            <a
              href="https://t.me/c/1775208220/10"
              className="arrow_button_selling_posts_links_doubled_box"
            >
              <img src={arrowToRight} />
            </a>
          </div>
          <div className="selling_posts_links_solo_box">
            <p>Тезисы почему Ultron</p>
            <a
              href="https://t.me/c/1775208220/21"
              className="arrow_button_selling_posts_links_doubled_box"
            >
              <img src={arrowToRight} />
            </a>
          </div>
          <div className="selling_posts_links_solo_box">
            <p>Как работает бинар на крупных суммах</p>
            <a
              href="https://t.me/c/1775208220/20"
              className="arrow_button_selling_posts_links_doubled_box"
            >
              <img src={arrowToRight} />
            </a>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
