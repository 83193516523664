import arrowImg from "../../img/arrowback.png";
import { Link } from "react-router-dom";
import React from "react";

export default function ArrowButtonPromotion() {
  const WindowBack = () => {
    window.history.back();
  };
  return (
    <div className="arrow_button_go_back_container" onClick={WindowBack}>
      <div>
        <img src={arrowImg} />
        <p>продвижение</p>
      </div>
    </div>
  );
}
