import React from "react";
import "./instructions.css";
import Zoom from "react-reveal/Zoom";
import Flip from "react-reveal/Flip";
import Slide from "react-reveal/Slide";
import razd from "./razd.svg";
import butt from "./butt.svg";
import video from "./video.png";
import mobraz from "./mobraz.svg";
import mobvideo from "./mobvideo.png";
import inst from "./in.svg";
const videos = [
  "https://www.youtube.com/embed/Y611qpV2vxM",
  "https://www.youtube.com/embed/zT6NPhPj5b4",
  "https://www.youtube.com/embed/8iFNaxAB0QI",
  "https://www.youtube.com/embed/6XXU9Hn0VpQ",
  "https://www.youtube.com/embed/5qFP6M1BUJU",
];
const instructions = [
  "регистрация в кабинете",
  "регистрация на биржу xt",
  "где приобрести usdt trc20",
  "как купить hub с биржи или с кошелька",
  "как вывести usdt с кабинета",
];
export default function Instructions() {
  return (
    <div style={{ paddingTop: "5vw", background: "#201c24" }}>
      <img src={inst} className="inst" alt="" />
      {videos &&
        document.body.clientWidth > 800 &&
        videos.length > 0 &&
        videos.map((em, index) => {
          return (
            <div className="bot_video">
              {index != 0 && <img src={razd} alt="" className="razd" />}
              <p className="hinst">{instructions[index]}</p>
              <img src={video} className="vid" alt="" />
              <iframe
                className="youtubeBot"
                src={em}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
      {videos &&
        document.body.clientWidth < 800 &&
        videos.length > 0 &&
        videos.map((em, index) => {
          return (
            <div className="bot_video">
              {index != 0 && <img src={mobraz} alt="" className="razd" />}
              <p className="hinst">{instructions[index]}</p>
              <img src={mobvideo} className="vid" alt="" />

              <iframe
                className="youtubeBot"
                src={em}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          );
        })}
    </div>
  );
}
