import "./profileSettings.css";
import AnimatedPage from "../animationPage";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import Burger from "../../components/burger/burger";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState, useRef } from "react";
import { apiUrl } from "../../const";
import QRarrows from "../../img/QRarrows.png";
import React from "react";
export default function ProfileSettings() {
  const [passwordsData, setPasswordsData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [name, setName] = useState(localStorage.name);
  const [newTel, setNewTel] = useState(localStorage.tel);
  const [errorPassword, setErrorPassword] = useState("");
  const updatePassword = () => {
    let data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("password", passwordsData.oldPassword);
    data.append("newPassword", passwordsData.newPassword);
    fetch(apiUrl + "/changePassword.php", {
      body: data,
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data == "ok") {
          alert("Пароль успешно изменен");
          localStorage.password = passwordsData.newPassword;
        } else {
          alert(data);
        }
      });
  };
  console.log(errorPassword);
  const Validate = () => {
    if (passwordsData.newPassword.length < 6) {
      setErrorPassword("Длина пароля должна быть не менее 6 символов");
    } else {
      if (passwordsData.newPassword == passwordsData.confirmPassword) {
        updatePassword();
      } else {
        setErrorPassword("Пароли не совпадают");
      }
    }
  };
  const HandleChange = (e) => {
    setPasswordsData({ ...passwordsData, [e.target.name]: e.target.value });
  };
  // const [referalLink, setReferalLink] = useState("")
  const [qrcodeLink, setQrcodeLink] = useState(localStorage.link);
  // const HandleReferalChange=(e)=>{
  //     setReferalLink(e.target.value)
  // }
  // const ChangeReferal=()=>{

  // }
  const firstInput = useRef(null);
  const secondInput = useRef(null);
  const thirdInput = useRef(null);
  const fourthInput = useRef(null);
  const fifthInput = useRef(null);
  const sixthInput = useRef(null);
  const sevenInput = useRef(null);
  const eightInput = useRef(null);
  const saveNew = () => {
    let data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("tgId", localStorage.getItem("tgId"));
    data.append("newName", name);
    data.append("newTel", newTel);

    fetch(apiUrl + "/changeData.php", {
      body: data,
      method: "post",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data == "ok") {
          alert("Данные успешно изменены");
          localStorage.setItem("loginerTel", newTel);
          localStorage.setItem("name", name);
        } else {
          alert(data);
        }
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fifthInput.current.style.transform = "scale(1)";
      sixthInput.current.style.transform = "scale(1)";
      sevenInput.current.style.transform = "scale(1)";
      eightInput.current.style.transform = "scale(1)";
    }, 500);
  }, []);

  return (
    <AnimatedPage>
      <div className="profile_settings_container">
        <ArrowButtonStart />

        <p className="profile_settings_title">Настройки</p>

        <p ref={eightInput} className="profile_settings_changeTitle">
          Редактировать
        </p>
        <input
          ref={fifthInput}
          className="fifthInput"
          placeholder="имя"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <input
          ref={sixthInput}
          className="sixthInput"
          placeholder="телефон"
          value={newTel}
          onChange={(e) => setNewTel(e.target.value)}
        ></input>
        <button
          ref={sevenInput}
          className="change_password sevenInput"
          onClick={() => saveNew()}
        >
          Сохранить
        </button>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
