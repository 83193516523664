import "./documentsPage.css";
import AnimatedPage from "../animationPage";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import DocumentsImg1 from "../../img/documentsImg1.png";
import DocumentsImg2 from "../../img/documentsImg2.png";
import DocumentsImg3 from "../../img/documentsImg3.png";
import { useEffect, useState, useRef } from "react";
import { apiUrl } from "../../const";
import React from "react";
export default function DocumentsPage() {
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  // useEffect(()=>{
  //     setTimeout(() => {
  //         LogoRef.current.style.transform="scale(1)"
  //         firstInput.current.style.transform="scale(1)"
  //         secInput.current.style.transform="scale(1)"
  //     }, 500);

  // },[])
  const goPoocoin = () => {
    window.open(apiUrl + "/Токеномка_rus.pdf", "_blank");
  };
  // const goPoocoin2=()=>{
  //     window.open(apiUrl+"/Lightpaper_rus.pdf","_blank")
  // }
  // const goPoocoin3=()=>{
  //     window.open(apiUrl+"/Whitepapre_rus.pdf","_blank")
  // }
  const [arrData, setArrData] = useState([]);
  const getElements = () => {
    fetch("https://cbt-version.ru/UltronAdminApi/Document/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setArrData(data);
      });
  };
  useEffect(() => {
    getElements();
  }, []);
  console.log(arrData);
  return (
    <AnimatedPage>
      <div className="documents_page_container">
        <ArrowButtonStart />
        <p className="documents_title">документы</p>
        <p className="documents_undertitle">
          <b>Изучите внимательно,</b> ведь старт всегда самое важное!
        </p>
        {arrData &&
          arrData.map((elem, index) => {
            if (elem.VISIBILITY == 1) {
              return (
                <div className="document_box" key={index}>
                  <img
                    className="document_box_img"
                    src={
                      "https://cbt-version.ru/UltronAdminApi/img/" +
                      elem.IMG_SRC
                    }
                  />
                  <div className="document_about">
                    <p className="document_about_text1">{elem.TITLE}</p>
                    <a
                      href={
                        "https://cbt-version.ru/UltronAdminApi/doc/" +
                        elem.FILE_SRC
                      }
                      target="_blank"
                    >
                      смотреть
                    </a>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </AnimatedPage>
  );
}
