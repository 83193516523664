import "./burger.css";
import burgericon from "../../img/menuburger.png";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import React from "react";
import BurgerMenu from "../../pages/burgerMenu/burgerMenu";
export default function Burger() {
  const [menuOpener, setMenuOpener] = useState(false);
  const burgerRef = useRef(null);
  const openMenu = () => {
    if (menuOpener) {
      document.getElementsByClassName("burger_menu_container")[0].style =
        "background: rgba(0, 0, 0, 0); width: 0vw";
      document.getElementsByClassName("burger_menu_links")[0].style.right =
        "-50vw";
      burgerRef.current.style.transform = "rotateZ(0deg)";
      setMenuOpener(false);
      setTimeout(() => {
        document.getElementsByClassName("burger_menu_links")[0].style.display =
          "none";
      }, 500);
    } else {
      document.getElementsByClassName("burger_menu_links")[0].style.display =
        "flex";
      setTimeout(() => {
        document.getElementsByClassName("burger_menu_links")[0].style.right =
          "0vw";
      }, 10);
      document.getElementsByClassName("burger_menu_container")[0].style =
        "background: rgba(0, 0, 0, 0.34); width: 50vw";
      burgerRef.current.style.transform = "rotateZ(90deg)";
      setMenuOpener(true);
    }
  };
  return (
    <div>
      <img
        ref={burgerRef}
        className="upper_icons_width upper_icons_burger"
        src={burgericon}
        onClick={openMenu}
      />
      <BurgerMenu setMenuOpener={setMenuOpener} burgerRef={burgerRef} />
    </div>
  );
}
