import "./ultronResources.css";
import ArrowButtonGoBack from "../../components/arrowButtonGoBack/arrowButtonGoBack";
import Burger from "../../components/burger/burger";
import resourcesImg from "../../img/resourcesImg.png";
import resourcesLines from "../../img/resourcesLines.png";
import ResourcesDownPart from "../../components/resourcesDownPart/resourcesDownPart";
import AnimatedPage from "../animationPage";
import resourcesSquareImg1 from "../../img/resourcesSquareImg1.png";
import resourcesSquareImg2 from "../../img/resourcesSquareImg2.png";
import resourcesSquareImg3 from "../../img/resourcesSquareImg3.png";
import resourcesSquareImg4 from "../../img/resourcesSquareImg4.png";
import resourcesSquareImg5 from "../../img/resourcesSquareImg5.png";
import resourcesSquareImg6 from "../../img/resourcesSquareImg6.png";
import { useEffect, useState, useRef } from "react";
import React from "react";
export default function UltronResources() {
  var requestArr = {
    sectionsFirst: [
      {
        id: 0,
        grader: 0,
        title: "Разработка мета вселенной",
        img: resourcesSquareImg1,
        className: "firstResourcesImg",
        delay: "0s",
        hrefer: "https://t.me/Ultron_Automatic/37",
      },
      {
        id: 1,
        grader: 1,
        title: "Сайт блокчейна Ultron",
        img: resourcesSquareImg2,
        className: "mainResourcesImg",
        delay: "0.05s",
        hrefer: "https://ultron.foundation/",
      },
      {
        id: 2,
        grader: 2,
        title: "GitHub",
        undertitle: "код блокчейна",
        img: resourcesSquareImg3,
        className: "SpecResourcesImg",
        delay: "0.1s",
        hrefer: "https://github.com/orgs/UltronFoundationDev/repositories",
      },
    ],
    sectionsSecond: [
      {
        id: 3,
        grader: 3,
        title: "Биржа UltronSwap",
        img: resourcesSquareImg4,
        className: "mainResourcesImg",
        delay: "0.15s",
        hrefer: "https://ultronswap.com/",
      },
      {
        id: 4,
        grader: 4,
        title: "Сайт маркетинга",
        img: resourcesSquareImg5,
        className: "mainResourcesImg",
        delay: "0.2s",
        hrefer: "https://www.backoffice.mavie.global/login",
      },
      {
        id: 5,
        grader: 5,
        title: "Explorer ULX",
        img: resourcesSquareImg6,
        className: "SpecResourcesImg",
        delay: "0.25s",
        hrefer: "https://ulxscan.com/",
      },
    ],
  };
  const LogoRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
    }, 500);
  }, []);
  return (
    <AnimatedPage>
      <div className="ultron_resources_container">
        <ArrowButtonGoBack />
        <Burger />
        <p className="resources_title">ресурсы ultron</p>
        <div className="resources_main_img_box">
          <img ref={LogoRef} className="resourcesImg" src={resourcesImg} />
          <p className="resources_team_project">команда проекта</p>
          <p className="resources_team_amount">более 40 человек</p>
          <img className="resourcesLines" src={resourcesLines} />
          <div className="resources_right_year"></div>
        </div>
        <div className="down_parts_row">
          {requestArr.sectionsFirst.map((requestArrs) => (
            <ResourcesDownPart
              key={requestArrs.id}
              grader={requestArrs.grader}
              hrefer={requestArrs.hrefer}
              delay={requestArrs.delay}
              title={requestArrs.title}
              undertitle={requestArrs.undertitle}
              img={requestArrs.img}
              className={requestArrs.className}
            />
          ))}
        </div>
        <div className="down_parts_row">
          {requestArr.sectionsSecond.map((requestArrs) => (
            <ResourcesDownPart
              key={requestArrs.id}
              grader={requestArrs.grader}
              hrefer={requestArrs.hrefer}
              delay={requestArrs.delay}
              title={requestArrs.title}
              img={requestArrs.img}
              className={requestArrs.className}
            />
          ))}
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
