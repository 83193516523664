import "./presentationUltron.css";
import videoPlaceholder from "../../../img/videoPlaceholder.png";
import ArrowButtonPresentation from "./arrowButtonPresentation";
import AnimatedPage from "../../animationPage";
import React from "react";
export default function PresentationReasonBlockChain() {
  return (
    <AnimatedPage>
      <div className="presentations_container">
        <ArrowButtonPresentation />
        <div className="presentation_titles">
          Суть блокчейна, почему Ultron?
        </div>
        <iframe
          width="99%"
          height="197"
          src="https://www.youtube.com/embed/v5_LMjIccoY?autoplay=1&mute=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="presentations_text">
          На рынок вышел новый блокчейн <br /> 1-го уровня, такой как Eth,{" "}
          <br /> Solana, Cordano, Polygon, Fantom, <br /> Bnb на котором можно
          делать <br /> тысячи разных разработок.
        </p>
      </div>
    </AnimatedPage>
  );
}
