import "./databasePage.css";
import StepsMavieBlock from "../../components/stepsBlock/StepsMavieBlock";
import ArrowButtonBack from "../../components/arrowButtonStart/arrowButtonStart";
import AnimatedPage from "../animationPage";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../img/searchIcon.png";
import React from "react";

export default function DatabasePageMavie() {
  const Navigate = useNavigate();
  const [elements, setElements] = useState();
  const [search, setSearch] = useState("");
  const [arr, setArr] = useState([]);
  console.log(elements);
  const getElements = () => {
    fetch("https://cbt-version.ru/UltronAdminApi/Page_Mavie/")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setElements(data);
      });
  };
  function getResults() {
    if (search != "") {
      fetch("https://cbt-version.ru/UltronAdminApi/Search/?query=" + search)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setArr(data);
        });
    } else {
      setArr([]);
    }
  }
  useEffect(() => {
    getElements();
  }, []);
  useEffect(() => {
    getResults();
  }, [search]);
  const GoToSearched = (type, id) => {
    if (type == "DOCUMENTS") {
      Navigate("/documents");
    }
    if (type == "PAGES_MAVIE") {
      Navigate("/sectionMavieContainer?id=" + id);
    }
    if (type == "PAGES") {
      Navigate("/sectionContainer?key=" + id);
    }
    if (type == "CARDS_MAVIE") {
      Navigate("/contentMavieContainer?key=" + id);
    }
    if (type == "CARDS") {
      Navigate("/contentContainer?key=" + id);
    }
  };
  return (
    <AnimatedPage>
      <div className="database_page_container">
        <ArrowButtonBack />
        <p className="database_page_title">база знаний</p>
        <div className="database_ultron_search_input_box">
          <div className="database_ultron_search_input_box_box">
            <input
              type="text"
              className="database_ultron_search_input"
              placeholder="поиск..."
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <img
              className="database_ultron_search_input_img"
              src={searchIcon}
            />
          </div>

          <div className="searcher_box">
            {arr &&
              arr.length > 0 &&
              arr.map((requestArrs, index) => {
                return (
                  <div className="searcher_result_box" key={index}>
                    <p>{requestArrs.TITLE}</p>
                    {requestArrs.TYPE == "DOCUMENTS" ? (
                      <a
                        href={
                          "https://cbt-version.ru/UltronAdminApi/doc/" +
                          requestArrs.SRC
                        }
                        target="_blank"
                      >
                        перейти
                      </a>
                    ) : (
                      <button
                        onClick={() =>
                          GoToSearched(requestArrs.TYPE, requestArrs.ID)
                        }
                      >
                        перейти
                      </button>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        {elements &&
          elements.length > 0 &&
          elements.map((requestArrs, index) => {
            if (requestArrs.VISIBILITY == 1) {
              return (
                <StepsMavieBlock
                  key={index}
                  ID={requestArrs.ID}
                  title={requestArrs.NAME}
                  number={requestArrs.POSITION}
                  imgBig={requestArrs.IMG_SRC}
                  index={index}
                />
              );
            }
          })}
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
